import './ProfilePic.scss';

function ProfilePic({ width, height, url, letter }) {

  const styleProps = { 
    minWidth: width,
    minHeight: height,
    backgroundImage: `url('${url}')`,
    backgroundColor: '#0e6595',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    alignItems: 'center',
    color: 'white'
  };

  return (
    <>
      {url 
        ? <div style={styleProps} className='profile-pic'></div>
        : <div style={styleProps} className='profile-pic'>{letter}</div>
      }
    </>
  );
}

export default ProfilePic;