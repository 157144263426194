import styles from '../styles.module.css';
import MeetingItem from './MeetingItem.js';

function MeetingList({ children }) {
  return (
    <div className={styles['meeting-list']}>
      {children}
    </div>
  )
}

export default MeetingList;