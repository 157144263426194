import { useEffect } from 'react';
import './Modal.scss';

function Modal({ show = false, onHide, children }) {
  const noScrolling = 'body-no-scroll';
  const showModal = show ? 'modal--open' : '';

  useEffect(() => {
    if (show) {
      document.body.classList.add(noScrolling);
    } else {
      if(document.body.classList.contains(noScrolling)) {
        document.body.classList.remove(noScrolling); 
      }
    }
  }, [show]);

  return (
    <div className={`modal ${showModal}`}>
      <div className='modal__container'>
        <div>
          {children}
          <div className="modal__buttons">
            <svg onClick={onHide} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;