import './CalendarDropdown.scss';
import CalendarDatePicker from '../../CalendarDatePicker/';
import { forwardRef } from 'react';

const CalendarDropdown = forwardRef((props, ref) => (
  <div ref={ref} className='calendar-dropdown'>
    <CalendarDatePicker {...props} />
  </div>
));

export default CalendarDropdown;