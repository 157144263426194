import './ProjectItem.scss';
import ProfilePic from '../../Common/ProfilePic';
import { ReactComponent as MeetingIcon } from '../../../assets/icons/meeting.svg';
import { ReactComponent as PerformanceIcon } from '../../../assets/icons/performance.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/project.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/icons/feedback.svg';
import { Link } from 'react-router-dom';

function ProjectItem({ to, picUrl, name, disabled = false, count = {}, ...rest }) {
  /* Remember to add links for meeting, teams, performance and feedback. */
  return (
    <Link style={{
      opacity: disabled ? '0.6' : '1'
    }} to={to} className="project-item" {...rest}>
      <div className="project-item__metadata">
        <div>
          <div>
            <p>{name}</p>
          </div>
        </div>
      </div>
      <div className="project-item__links">
        <a>
          <div className="project-item__count">5</div>
          <span>Meetings</span>
        </a>
        <a>
          <div className="project-item__count">5</div>
          <span>Reports</span>
        </a>
        <a>
          <div className="project-item__count">5</div>
          <span>Reviews</span>
        </a>
        <a>
          <div className="project-item__count">5</div>
          <span>Members</span>
        </a>
      </div>
    </Link>
  )
}

export default ProjectItem;