import { useRef } from 'react';
import { useOutsideClick } from '../../hooks/dom.js';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import ProfilePic from '../Common/ProfilePic/';

function SettingsDropdown({isOpen, setIsOpen, profileUrl, profileLetter, children}) {
  const dropdownRef = useRef();
  const dropdownStyle = { display: isOpen ? 'block' : 'none' }

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className={styles['settings-dropdown']}>
      <div onClick={() => setIsOpen(!isOpen)}>
        <ProfilePic width="2rem" height="2rem" url={profileUrl} letter={profileLetter}/>
      </div>
      <div style={dropdownStyle} className={styles['settings-dropdown-container']}>
          {children[0]}
        <div className={styles['settings-dropdown-options']}>
          {children.slice(1, children.length)}
        </div>
      </div>
    </div>
  );
}

function SettingsHeader ({ title, role }) {
  return (
    <div className={styles['settings-dropdown-header']}>
      <p>{title}</p>
      <p>{role}</p>
    </div>
  );
}

function SettingsOption ({ title, to, icon: Icon, onSelect, whenClicked }) {
  const Element = to ? Link : 'a';
  const run = () => {
    onSelect(false);
    if (typeof whenClicked === 'function') {
      whenClicked();
    }
  }
  return (
    <Element onClick={() => run()} to={to} className={styles['settings-option']}>
      <Icon />
      <p>{title}</p>
    </Element>
  )
}

export {
  SettingsDropdown,
  SettingsHeader,
  SettingsOption
}