import "./People.scss";
import PeopleItem from "./Content/PeopleItem.js";
import { useEffect, useState } from 'react';
import Button from '../Common/Button/';
import Input from '../Common/Input/';
import Modal from '../Common/Modal/';
import ManagePeople from './Modals/ManagePeople';
import PeopleDetails from './Modals/PeopleDetails';
import { ReactComponent as FilterIcon } from '../../assets/icons/filled/filter.svg';
import { ReactComponent as UserAddIcon } from '../../assets/icons/filled/useradd.svg';
import { useAgent } from '../../hooks/agent.js';
import { useProject } from '../../hooks/project.js';
import { useParams } from "react-router-dom";
import Loading from '../Common/Loading'
import Alert from '../Common/Alert'

function PeopleSelect({ name, count, selected, onClick }) {
  const withCount = count > 0 ? `(${count})` : '';
  
  return (
    <div className="people__select" onClick={onClick}>
      <a className={`${ selected ? 'people__select--selected' : ''}`}>
        {name} {withCount}
      </a>
    </div>
  )
}

function People () {
  const [view, setView] = useState('all');
  const [createPeople, setCreatePeople] = useState(false);
  const [managePeople, setManagePeople] = useState(false);
  const [people, setPeople] = useState(null);
  const { currentProjectState } = useProject();
  const [{ data, isLoading, isError }, doFetch] = useAgent(
    { data: {
      members: []
    } }, 
    `https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`);
  const [query, setQuery] = useState('');
  const [popUpMessage, setPopUpMessage] = useState({
    msg: '',
    style: {}
  });
  
  let { id } = useParams();

  useEffect(() => {
    doFetch(`https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`)
  }, [currentProjectState.id])

  return (
    <div class="people">
      <div className="people__header">
        <div className="people__selector">
          
          <div className="people__buttons">
            <Button
              color="primary" 
              onClick={() => { setManagePeople(true) }}>
              <UserAddIcon /> Manage members
            </Button>
          </div>
        </div>
        <Input value={query} 
          placeholder="Search for someone within the company"
          onChange={(e) => setQuery(e.target.value)} 
        />
      </div>
      <div className="people-list">
        {isError && <div>Something went wrong ...</div>}
        { 
          isLoading 
          ? ( <Loading color="#0f6695"/> )
          : (data.data.members.map((user, index) => {
            return (
              <PeopleItem key={index} name={user.firstName} lastName={user.lastName} 
                role={user.role}
                picUrl={user.url}
                supervisedBy="Lucas Martinez" />
            )
          }))
        }
      </div>
      <Modal show={managePeople} onHide={() => setManagePeople(false)}>
        {managePeople && <ManagePeople onCancel={() => setManagePeople(false)} setPopUpMessage={setPopUpMessage}/>}
      </Modal>
      <Modal show={people} onHide={() => setPeople(false)}>
        {people && <PeopleDetails />}
      </Modal>
      {popUpMessage.msg && <Alert msg={popUpMessage.msg} style={popUpMessage.style}/>}
    </div>
  )
}

export { People }