import styles from './styles.module.css';
import './SettingsItem.scss';
import { Route, useParams, Redirect} from 'react-router-dom';
import { useEffect, useState } from 'react';
import FilterSection from './Sidebar/FilterSection.js';
import { useLocation, useRouteMatch, useHistory, NavLink } from 'react-router-dom';
import { ReactComponent as EmployeeIcon } from '../../assets/icons/employee.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/project.svg';
import { ReactComponent as TeamIcon } from "../../assets/icons/team.svg";
import { People } from '../../components/People/People';
import { Teams } from '../../components/Teams/Teams';
import { Projects } from '../../components/Projects/Projects';
import { useProject } from '../../hooks/project';

function Sidebar ({ children }) {
  return (
    <div className={styles['sidebar']}>
      {children}
    </div>
  );
}

function Content ({ children }) {
  return (
    <div className={styles['content']}>
      {children}
    </div>
  );
}

function SettingsItem({ to, icon: Icon, name, count }) {
  return (
    <NavLink activeClassName='settings-item--selected' className='settings-item' to={to}>
      <Icon />
      <p className="settings-item__name">{name}</p>
      { count && <div className='settings-item__count'>{count}</div> }
    </NavLink>
  )
}

function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

function Settings ({ children }) {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);

  const [meetings, setUrl] = useState([]);
  let { id } = useParams();
  const { currentProjectState } = useProject();

  const toggleItem = (itemName) => {
    let selectedItems = [];
    let setSelectedItems;
  
    switch(itemName) {
      case "project":
        selectedItems = selectedProjects;
        setSelectedItems = setSelectedProjects;
        break;
      case "manager":
        selectedItems = selectedManagers;
        setSelectedItems = setSelectedManagers;
        break;
      case "supervisor":
        selectedItems = selectedSupervisors;
        setSelectedItems = setSelectedSupervisors;
        break;
      default:
        break;
    }

    return (item) => {
      item.checked = !item.checked;

      if (item.checked) {
        setSelectedItems(selectedItems.concat(item));
      } else {
        setSelectedItems(selectedItems.filter(i => i.id !== item.id));
      }
    } 
  }

  

  return (
    <div className={styles['meetings-container']}>
      {/*meetings.isError && <p>Something went wrong...</p> */}
      {meetings.isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className={styles['meetings-content']}>
          <Sidebar>
            <FilterSection title={`${currentProjectState.name} Settings`}>
              <SettingsItem name="Members" icon={EmployeeIcon} to={`/project/${id}/settings/people`} count="2509"/>
              <SettingsItem name="Teams" icon={TeamIcon} to={`/project/${id}/settings/teams`} count="29"/>
            </FilterSection>
          </Sidebar>
          <Content>
            <Route path="/project/:id/settings/people">
              <People />
            </Route>
            <Route path="/project/:id/settings/teams">
              <Teams />
            </Route>
          </Content>
        </div>
      )}
    </div>
  );
}

export {
  Settings
}