import styles from './styles.module.css';
import { useEffect, useState } from 'react';
import MeetingList from './Content/MeetingList.js';
import MeetingSession from './Content/MeetingSession.js';
import MeetingItem from './Content/MeetingItem.js';
import FilterSection from './Sidebar/FilterSection.js';
import Input from '../Common/Input';
import Button from '../Common/Button';
import { ReactComponent as VideocameraIcon } from '../../assets/icons/filled/videocamera.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/filled/calendar.svg';
import Modal from '../Common/Modal';
import MeetingFilter from '../Meetings/Modals/MeetingFilter.js';
import CreateMeeting from '../Meetings/Modals/CreateMeeting.js';
import MeetingDetails from '../Meetings/Modals/MeetingDetails.js';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import TeamSidebarItem from "../Teams/Content/TeamSidebarItem.js";
import KPICard from './Content/KPICard';


function Sidebar ({ children }) {
  return (
    <div className={styles['sidebar']}>
      {children}
    </div>
  );
}

function Content ({ children }) {
  return (
    <div className={styles['content']}>
      {children}
    </div>
  );
}

function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

function Performance ({ children }) {
  const searchParams = useSearchParams();
  const history = useHistory();
  const { path } = useRouteMatch()
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [meeting, setMeeting] = useState(searchParams.get('meeting') || null);
  const [view, setView] = useState(searchParams.get('view') || 'overall');
  const [oneOnOne, setOneOnOne] = useState(searchParams.get('1to1') || false);
  const [triad, setTriad] = useState(searchParams.get('triad') || false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [createMeetingView, setCreateMeetingView] = useState('create');


  let dateFromParams = searchParams.get('date') && searchParams.get('date').split('-');
  dateFromParams = dateFromParams && new Date(dateFromParams[0], dateFromParams[1] - 1, dateFromParams[2]);

  const [date, setDate] = useState(dateFromParams || null);
  const [meetings, setUrl] = useState([]);
  const [createMeeting, setCreateMeeting] = useState(false);
  const [meetingFilter, setMeetingFilter] = useState(false);
  const [filter, setFilter] = useState('project');

  const [teams, setTeams] = useState([{
    id: 1,
    name: "Altice",
    count: 9
  }, {
    id: 2,
    name: "The Verge",
    count: 5
  }, {
    id: 3,
    name: "MLB Network",
    count: 4
  }, {
    id: 4,
    name: "The New York Times",
    count: 7
  }, {
    id: 5,
    name: "Netflix",
    count: 2
  }]);
  const [yourTeams, setYourTeams] = useState([{
      id: 1,
      name: "Voice",
      color: '#30a1e2'
    },
    {
      id: 2,
      name: "Chat",
      color: '#F44336'
    }
  ])
  const [selectedTeam, setSelectedTeam] = useState(1);

  useEffect(() => {
    const qs = new URLSearchParams();
    
    view && qs.append('view', view);
    query && qs.append('query', query);
    date && qs.append('date', date.toISOString().split('T')[0])
    oneOnOne && qs.append('1to1', oneOnOne);
    triad && qs.append('triad', triad);
    meeting && qs.append('meeting', meeting);
    
    //history.replace(`${path}?${qs.toString()}`)
  }, [history, 
    path, 
    query,
    view, 
    date,
    oneOnOne, 
    triad, 
    meeting
  ]);

  const removeProject = project => setSelectedProjects(selectedProjects.filter(p => p.id !== project.id));
  const removeManager = manager => setSelectedManagers(selectedManagers.filter(m => m.id !== manager.id));
  const removeSupervisor = supervisor => setSelectedSupervisors(selectedSupervisors.filter(s => s.id !== supervisor.id));

  const toggleItem = (itemName) => {
    let selectedItems = [];
    let setSelectedItems;
  
    switch(itemName) {
      case "project":
        selectedItems = selectedProjects;
        setSelectedItems = setSelectedProjects;
        break;
      case "manager":
        selectedItems = selectedManagers;
        setSelectedItems = setSelectedManagers;
        break;
      case "supervisor":
        selectedItems = selectedSupervisors;
        setSelectedItems = setSelectedSupervisors;
        break;
      default:
        break;
    }

    return (item) => {
      item.checked = !item.checked;

      if (item.checked) {
        setSelectedItems(selectedItems.concat(item));
      } else {
        setSelectedItems(selectedItems.filter(i => i.id !== item.id));
      }
    } 
  }

  const showMeetingItem = (e, id) => {
    e.preventDefault();
    setMeeting(id);
  }

  const openMeetingFilter = (filter) => {
    setFilter(filter);
    setMeetingFilter(true);
  }

  return (
    <div className={styles['meetings-container']}>
      {/*meetings.isError && <p>Something went wrong...</p> */}
      {meetings.isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className={styles['meetings-content']}>
          <Content>
            <p className={styles['content-title']}>Meetings</p>
            <div className={styles['content-header']}>
              <div className={styles['session-selector']}>
                <MeetingSession name="Overall"  selected={view === 'overall'} onClick={() => setView('overall')} />
                <MeetingSession name="Per Team" count='3' selected={view === 'team'} onClick={() => setView('team')} />
                <MeetingSession name="Per Agent" count='2' selected={view === 'agent'} onClick={() => setView('agent')} />
                <div style={{visibility: 'hidden'}} className={styles['buttons']}>
                  <Button
                    color="gradient" 
                    text="Manage Metrics" 
                    onClick={() => {
                      setCreateMeetingView('myschedule');
                      setCreateMeeting(true)
                    }}>
                    <CalendarIcon />
                  </Button>
                  <Button
                    color="primary" 
                    text="Create a PIP" 
                    onClick={() => {
                      setCreateMeetingView('create');
                      setCreateMeeting(true)
                    }}>
                    <VideocameraIcon />
                  </Button>
                </div>
              </div>
                <Input value={query} 
                  placeholder={`Search for ${view === 'team' ? 'a team' : 'an agaent'}`}
                  onChange={(e) => setQuery(e.target.value)} 
                />
            </div>
            <MeetingList>
              <span className={styles['meeting-date']}>Closed Deals</span>
              <div className="divider"></div>
              <KPICard />
            </MeetingList>
            <br />
            <MeetingList>
              <span className={styles['meeting-date']}>Finished Calls</span>
              <div className="divider"></div>
              <KPICard />
            </MeetingList>
            <Modal show={createMeeting} onHide={() => setCreateMeeting(false)}>
              {createMeeting && <CreateMeeting view={createMeetingView} setView={setCreateMeetingView} />}
            </Modal>
            <Modal show={meetingFilter} onHide={() => setMeetingFilter(false)}>
              {meetingFilter && 
                <MeetingFilter filter={filter} 
                  onSelectFilter={setFilter}
                  selectedProjects={selectedProjects}
                  selectedManagers={selectedManagers}
                  selectedSupervisors={selectedSupervisors}
                  onSelectProject={toggleItem("project")}
                  onSelectManager={toggleItem("manager")}
                  onSelectSupervisor={toggleItem("supervisor")}
                />
              }
            </Modal>
            <Modal show={meeting} onHide={() => setMeeting(false)}>
              {meeting && <MeetingDetails />}
            </Modal>
          </Content>
        </div>
      )}
    </div>
  );
}

export {
  Performance
}