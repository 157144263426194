import styles from './styles.module.css';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

function RemovableFilterItem({ onRemove, children }) {
  return (
    <div className={styles['removable-filter-item']}>
      <div>
        {children}
        <span onClick={onRemove}>
          <CloseIcon />
        </span>
      </div>
    </div>
  )
}

export default RemovableFilterItem;