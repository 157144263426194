import styles from '../styles.module.css';
import ProfilePic from '../../Common/ProfilePic/';

function Participant({ name, role, profileUrl}) {
  return (
    <div className={styles['participant']}>
      <ProfilePic width="1.875rem" height="1.875rem" url={profileUrl} letter={name.substring(0, 1)} />
      <div className={styles['participant-metadata']}>
        <p>{name}</p>
        <p>{role}</p>
      </div>
    </div>
  );
}

function MeetingItem({ type, time, activity, participants, ...rest }) {
  const url = new URL(window.location.href);
  url.searchParams.append("meeting", 1);

  return (
    <a className={styles['meeting-item']} href={`${url.pathname}${url.search}`} {...rest}>
      <div className={styles['meeting-info']}>
        <div className={styles['meeting-time']}>
          <p className={styles['meeting-item-header']}>Happening At</p>
          <p>{time}</p>
        </div>
        <div className={styles['meeting-type']}>
          <p className={styles['meeting-item-header']}>Type</p>
          <p>{type}</p>
        </div>
        <div className={styles['meeting-activity']}>
          <p className={styles['meeting-item-header']}>Purpose</p>
          <p>{activity}</p>
        </div>
      </div>
      <div className={styles['meeting-participants']}>
        <p className={styles['meeting-item-header']}>Participants</p>
        <div>
          {participants.map((participant, index) => {
              return (
                <Participant key={index} 
                  name={`${participant.firstName} ${participant.lastName}`}
                  role={participant.role}
                  profileUrl={null} />
              )
            })
          }
        </div>
      </div>
      <p className={styles['meeting-confirmation']}>None of the participants have confirmed that this meeting took place.</p>
    </a>
  )
}

export default MeetingItem;