import styles from '../styles.module.css';
import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';

function TriadForm() {
  const pwCanvasRef = useRef();
  const cwCanvasRef = useRef();

  useEffect(() => {
    const pwChart = new Chart(pwCanvasRef.current, {
      type: 'bar',
      data: {
        labels: ['CSAT', 'Effort', 'ACL'],
        datasets: [{
          label: '',
          data: [25, 40, 11],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawOnChartArea: false
            }
          }],
          x: {
            ticks: {
              display: false
            },
            title: {
              display: true,
              text: 'Higher is better',
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 50
            },
            min: 0,
            max: 100,
            beginAtZero: true,
            title: {
              display: false,
              text: 'Scale'
            }
          }
        }
      }
    });

    const cwChart = new Chart(cwCanvasRef.current, {
      type: 'bar',
      data: {
        labels: ['CSAT', 'Effort', 'ACL'],
        datasets: [{
          label: '',
          data: [45, 42, 20],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            }
          }],
          x: {
            ticks: {
              display: false
            },
            title: {
              display: true,
              text: 'Higher is better',
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 50
            },
            min: 0,
            max: 100,
            beginAtZero: true,
            title: {
              display: false,
              text: 'Scale'
            }
          }
        }
      }
    });

    return function cleanup () {
      pwChart.destroy();
      cwChart.destroy();
    }
  }, [])

  return (
    <div>
      <p className={styles['head-text']}>Performance Status</p>
      <div className={styles['shadow-stats']}>
        <div>
          <p>WTD: Week to Date</p>
          <canvas ref={pwCanvasRef} width="150" height="100"></canvas>
        </div>
        <div>
          <p>MTD: Month to Date</p>
          <canvas ref={cwCanvasRef} width="150" height="100"></canvas>
        </div>
      </div>
      <div>
        <p className={styles['head-text']}>Comment #1</p>
      </div>
      <div className={styles['public-note']} contentEditable={true}>Hola</div>
      <div>
        <p className={styles['head-text']}>Comment #2</p>
      </div>
      <div className={styles['public-note']} contentEditable={true}>Hola</div>
      <div>
        <p className={styles['head-text']}>Comment X</p>
      </div>
      <div className={styles['public-note']} contentEditable={true}>Hola</div>
      <p style={{marginTop: '20px'}}className={styles['head-text']}>Opportunity Zone</p>
      <div className={styles['shadow-stats']}>
        <div>
          <p>Agent Opportunities</p>
          <div className={styles['pip-note']} contentEditable={true}>Hola</div>
        </div>
        <div>
          <p>Supervisor Opportunities</p>
          <div className={styles['pip-note']} contentEditable={true}>Hola</div>
        </div>
      </div>
      <div>
        <p className={styles['head-text']}>Supervisor Commitments</p>
      </div>
      <div className={styles['public-note']} contentEditable={true}>Hola</div>
    </div>
  )
}

export default TriadForm;