import './TeamSidebarItem.scss';
import ProfilePic from '../../Common/ProfilePic';
import { ReactComponent as MeetingIcon } from '../../../assets/icons/meeting.svg';
import { ReactComponent as PerformanceIcon } from '../../../assets/icons/performance.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/project.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/icons/feedback.svg';
import { Link } from 'react-router-dom';

function TeamSidebarItem({ name, count, selected, ...rest }) {
  /* Remember to add links for meeting, teams, performance and feedback. */
  return (
    <div className={`team-item ${selected ? 'team-item--selected' : ''}`} {...rest}>
      { count && <div className='team-item__count'>{count}</div> }
      <p className="team-item__name">{name}</p>
    </div>
  )
}

export default TeamSidebarItem;

