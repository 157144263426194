import './Button.scss';

export default function Button ({ color, text, disabled, children, ...rest }) {
  const isDisabled = disabled ? 'disabled': '';
  const gradient = color === 'gradient' ? `button--gradient ${isDisabled}` : '';
  const primary = color === 'primary' ? `button--primary ${isDisabled}`  : '';
  const transparent = color === 'transparent' ? `button--transparent ${isDisabled}` : '';
  const danger = color === 'danger' ? `button--danger ${isDisabled}`: '';
  const colorModifier = `${gradient} ${primary} ${transparent} ${danger}`;
  
  return (
    <button className={`button ${colorModifier}`} {...rest}>
      {children}
    </button>
  )
}