import styles from './styles.module.css';
import Checkbox from '../../Common/Checkbox/';

function TypeFilterItem({ title, ...rest }) {
  return (
    <div className={styles['type-filter-item']}>
      <Checkbox {...rest} />
      <span className={styles['type-title']}>{title}</span>
    </div>
  );
}

export default TypeFilterItem;