import './MeetingSchedule.scss';

function Item ({ title, dots = false }) {
  return (
    <div className="meeting-schedule__content-item">
      <span>{title}</span>
      { dots && 
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      }
    </div>
  )
}

export default function MeetingSchedule () {

  return (
    <div className="meeting-schedule">
      <div className="meeting-schedule__content-header-row">
          <div className="meeting-schedule__content-header-item"></div>
          <div className="meeting-schedule__content-header-item">00:00 - 00:15</div>
          <div className="meeting-schedule__content-header-item">00:15 - 00:30</div>
          <div className="meeting-schedule__content-header-item">00:30 - 00:45</div>
          <div className="meeting-schedule__content-header-item">00:45 - 00:60</div>
        </div>
      <div className="meeting-schedule__content">
        <div className="meeting-schedule__content-row">
          <Item title="7:00 a.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="8:00 a.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="9:00 a.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="10:00 a.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="11:00 a.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="12:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="1:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="2:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="3:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="4:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="5:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="6:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="7:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="8:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="9:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="10:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
        <div className="meeting-schedule__content-row">
          <Item title="11:00 p.m."/>
          <Item title="Triad Coach"/>
          <Item title=""/>
          <Item title=""/>
          <Item title=""/>
        </div>
      </div>
    </div>
  )
}