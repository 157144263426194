import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import UnauthenticatedApp from './components/UnauthenticatedApp.js';
import AuthenticatedRoute from './components/AuthenticatedRoute.js';
import NotFound from './components/NotFound.js';
import { Meetings } from './components/Meetings/Meetings.js';
import { People } from './components/People/People.js';
import { Teams } from './components/Teams/Teams.js';
import { Performance } from './components/Performance/Performance.js';
import { Reviews } from './components/Reviews/Reviews.js';
import { Settings } from './components/Settings/Settings.js';
import { Projects } from './components/Projects/Projects.js';
import { Home } from './components/Home/Home.js';

function App() {  
  return (
    <div className="app">
      <Switch>
        <AuthenticatedRoute exact path="/">
          <Projects />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/project/:id">
            <Route path="/project/:id/meetings">
              <Meetings />
            </Route>
            <Route path="/project/:id/performance-forms">
              <Reviews />
            </Route>
            <Route path="/project/:id/performance-indicators">
              <Performance />
            </Route>
            <Route path="/project/:id/settings">
              <Settings />
            </Route>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/my-profile" />
        <Route exact path='/reset-password'>
          <UnauthenticatedApp view="reset-pw" />
        </Route>
        <Route exact path='/invite/:id'>
          <UnauthenticatedApp view="setup" />
        </Route>
        <Route exact path='/forgot-password'>
          <UnauthenticatedApp view="forgot" />
        </Route>
        <Route exact path='/login'>
          <UnauthenticatedApp view="login" />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
