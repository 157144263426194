import styles from './CreateTeam.module.css';
import { useRef, useState, useEffect } from 'react';
import Input from '../../Common/Input/';
import Checkbox from '../../Common/Checkbox/';
import Button from '../../Common/Button/';
import ProfilePic from '../../Common/ProfilePic/';
import { useAgent } from '../../../hooks/agent';
import { useAuth } from '../../../hooks/auth';
import { useProject } from '../../../hooks/project';
import Loading from '../../Common/Loading'
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const PeopleItem = ({ name, url }) => (
  <div className={styles['people-item']}>
    <ProfilePic width="1.5625rem" height="1.5625rem" url={url} letter={name.substring(0,1)}/>
    <p>{name}</p>
  </div>
);

function FilterItem({ role, children, ...rest }) {
  return (
    <div className={styles['filter-item']}>
      <Checkbox {...rest}/>
      {children}
      <p className={styles['filter-item-role']}>{role}</p>
    </div>
  );
}

function CreateTeam({ onCancel, setPopUpMessage }) {
  const { authState } = useAuth();
  const [creating, setCreating] = useState(false);
  const meetingContainerRef = useRef();
  const [usersFilter, setUsersFilter] = useState('search');
  const { currentProjectState } = useProject();

  const [{ data, isLoading, isError }, doFetch] = useAgent({ 
    data: {
      members: []
    }
  }, 
    `https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [name, setName] = useState('');
  const [query, setQuery] = useState('');
  const history = useHistory()
  
  const isSearchEnabled = usersFilter === 'search' ? true : false;

  function toggleUser(id) {
    if (isUserSelected(id)) {
      const listWithoutCurrentUser = selectedUsers.filter(userId => userId != id);
      setSelectedUsers(listWithoutCurrentUser);
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  }

  useEffect(() => {
    console.log(data.data.members);
    setSelectedUsers([authState.userInfo._id]);
  }, [])

  function isUserSelected(id) {
    return (selectedUsers.includes(id))
  }

  function isUserDisabled(id) {
    return (authState.userInfo._id === id);
  }

  async function createTeam() {
    try {
      setCreating(true);
      const result = await axios.post('https://outplexperformancemanagement.com/api/team', {
        name,
        kpis: [],
        members: selectedUsers,
        project: currentProjectState.id,
        createdBy: authState.userInfo._id
      });

        if (result.status === 201) {
          setCreating(false);
          onCancel();
          setPopUpMessage({
            msg: `Creation successful. You can now manage ${name}.`,
            style: {
              borderTop: '5px solid #208bc5',
              backgroundImage: 'linear-gradient(-180deg, #fff, #f5f8f9 99%)',
              color: '#0c6493',
              width: '300px',
              top: '120px'
            }
          })
          setTimeout(function() { 
            setPopUpMessage({
              msg: "",
              style: {}
            })
            history.go(window.location.href);
          }, 2000);
        }
        console.log(result);
    } catch (err) {
      setCreating(false);
      console.dir(err);
    }
  }

  return (
    <div ref={meetingContainerRef} className={`${styles['create-meeting']}`}>
      <div className={styles['create-meeting-header']}>
        <p className={styles['header-name']}>Create a Team</p>
      </div>
      <div className={styles['create-meeting-content']}>
        <div className={styles['location']}>
          <div className={styles['address-link']}>
            <p>Choose a name for this team</p>
            <Input placeholder="Type your team's name" value={name} onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className={styles['participants']}>
          <p>Choose the members of this team</p>
          <div className={styles['participants-header']}>
            <div className={styles['participants-search-container']}>
              <Input placeholder={`Search for someone within ${currentProjectState.name}`}
                onChange={(e) => setQuery(e.target.value)}
                disabled={!isSearchEnabled} 
              />
            </div>
            <div className={styles['search-or-selected']}>
              <a onClick={() => setUsersFilter('search')} 
                className={usersFilter === 'search' ? styles['selected-selector'] : ''}>
                Search
              </a>
              <a onClick={() => setUsersFilter('selected')} 
                className={usersFilter === 'selected' ? styles['selected-selector'] : ''}>
                Selected <span>{selectedUsers.length}</span>
              </a>
            </div>
          </div>
          <div className={styles['search-content']}>
            <div className={styles['filter-content-items']}>
            {isError && <div>Something went wrong ...</div>}
            { usersFilter === 'search' && 
              (isLoading 
                ? ( <Loading color="#0f6695"/> )
                : (data.data.members
                  .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                  .map((user, index) => {
                    return (
                      <FilterItem key={index} role={`${isUserDisabled(user._id) ? user.role + " (You)" : user.role}`}
                        onCheck={() => toggleUser(user._id)} 
                        checked={isUserSelected(user._id)}
                        disabled={isUserDisabled(user._id)}>
                        <PeopleItem url={user.url}
                          name={`${user.firstName} ${user.lastName}`}
                        />
                      </FilterItem>
                    )
                  }))
              )
            }
            { usersFilter === 'selected' &&
              ( data.data.members
                .filter(user => isUserSelected(user._id))
                .map((user, index) => {
                  return (
                    <FilterItem key={index} role={`${isUserDisabled(user._id) ? user.role + " (You)" : user.role}`}
                      onCheck={() => toggleUser(user._id)} 
                      checked
                      disabled={isUserDisabled(user._id)}>
                      <PeopleItem url={user.url} 
                        name={`${user.firstName} ${user.lastName}`}
                      />
                    </FilterItem>
                )})
              )
            }
            </div>
          </div>
        </div>
      </div>
      <div className={styles['create-meeting-footer']}>
        <div className={styles['create-meeting-buttons']}>
          <Button color="transparent" onClick={() => onCancel()}>Cancel</Button>
          <Button style={{ 
            minWidth: '70px',
            maxHeight: '40px',
            height: '40px'
          }} color="primary" onClick={() => createTeam()}>
            {creating
              ? <Loading color="#fff"/>
              : 'Create'
            } 
          </Button>
        </div>
      </div> 
    </div>
  );
}

export default CreateTeam;