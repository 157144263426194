export const extractRouteHead = function(path) {
  const str = path === '/' ? '/' : path;
  return str.replace(/[^/]/g, "").length > 1 ? 
    str.substring(1, str.indexOf('/', 1)) : 
    str.substring(1, str.length);
}

export function startsWith (str, array) {
  let i ;
  for(i = 0; i < array.length; i++) {
    if(str.startsWith(array[i])) {
      return true;
    }
  }
  return false;
}

export function getTimeRanges(interval, language) {
  const ranges = [];
  const date = new Date();
  const format = {
    hour: 'numeric',
    minute: 'numeric'
  };

  for (let minutes = 0; minutes < 24 * 60; minutes = minutes + interval) {
    date.setHours(0);
    date.setMinutes(minutes);
    let timeString = date.toLocaleTimeString(language, format); 
    ranges.push(timeString.replace('AM', "a.m.").replace("PM", "p.m."));
  }

  return ranges;
}