import './Checkbox.scss';

function Checkbox({ checked = false, disabled = false, onCheck }) {
  return (
    <label className='checkbox'>
      <span className='checkbox__input'>
        <input type="checkbox" 
          disabled={disabled}
          checked={checked}
          onChange={onCheck} 
        />
        <span className='checkbox__control'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
            <path fill='none' stroke='#ffffff' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' />
          </svg>
        </span>
      </span>
    </label>
  );
}

export default Checkbox;