import './Input.scss';

function Input ({ type, ...rest }) {
  const isDisabled = `
    ${rest.disabled ? 'input--disabled' : ''}
  `
  return (
    <input className={`input ${isDisabled}`} {...rest} />
  );
}

export default Input;