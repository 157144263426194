import { useAuth } from '../hooks/auth.js';
import { Redirect } from 'react-router-dom';
import LoginPage from './Login/Login.js';

function UnauthenticatedApp({ view }) {
  const { isAuthenticated } = useAuth();

  return isAuthenticated() ? (
    <Redirect to="/" />
  ) : (
    <div className='unauthenticated-app'>
      <LoginPage view={view}/>
    </div>
  );
}

export default UnauthenticatedApp;