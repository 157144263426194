import styles from './styles.module.css';
import { useState, useEffect } from 'react';
import Input from '../../Common/Input/';
import Checkbox from '../../Common/Checkbox/';
import ProfilePic from '../../Common/ProfilePic/';
import Button from '../../Common/Button/';
import SelectButton from '../../Common/SelectButton/';
import CalendarDropdown from '../../Common/Dropdowns/CalendarDropdown/';
import DefaultDropdown from '../../Common/Dropdowns/DefaultDropdown/';
import { useAgent } from '../../../hooks/agent';
import { useAuth } from '../../../hooks/auth';
import Loading from '../../Common/Loading';
import { useProject } from '../../../hooks/project';
import axios from 'axios';
import { useHistory } from 'react-router';


import { getTimeRanges } from '../../../utils.js';

const PeopleItem = ({ name, url }) => (
  <div className={styles['people-item']}>
    <ProfilePic width="1.5625rem" height="1.5625rem" url={url} letter={name.substring(0,1)}/>
    <p>{name}</p>
  </div>
);

function FilterOption ({ title, count, selected, ...rest }) {
  const isSelected = selected ? styles['filter-option-active'] : '';
  return (
    <a className={`${styles['filter-option']} ${isSelected}`} {...rest}>
      <div>
        <p>{title}</p>
        {count && <div className={styles['filter-option-count']}>{count}</div>}
      </div>
    </a>
  )
}

function FilterItem({ role, children, ...rest }) {
  return (
    <div className={styles['filter-item']}>
      <Checkbox {...rest}/>
      {children}
      <p className={styles['filter-item-role']}>{role}</p>
    </div>
  );
}

const formatDate = (date) => {
  return date.toLocaleString('en-GB', { 
    day: 'numeric', 
    month: 'short',
    year: 'numeric',
    weekday: 'short'
  })
};

const steps = 2;
const times = getTimeRanges(15, 'en');

function MeetingDetails({ meetingId, setPopUpMessage, onCancel }) {
  const { authState } = useAuth();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [participantsFilter, setParticipantsFilter] = useState('search');
  
  const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
  const [isTimeFromSelectOpen, setIsTimeFromSelectOpen] = useState(false);
  const [isTimeToSelectOpen, setIsTimeToSelectOpen] = useState(false);
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false);
  const [isPurposeSelectOpen, setIsPurposeSelectOpen] = useState(false);
  const [isProjectSelectOpen, setIsProjectSelectOpen] = useState(false);

  const { currentProjectState } = useProject();

  const [filter, selectFilter] = useState('details');

  const noOverflow = isDateSelectOpen 
    || isTimeFromSelectOpen 
    || isTimeToSelectOpen
    || isTypeSelectOpen
    || isPurposeSelectOpen 
    || isProjectSelectOpen ? styles['select-open'] : '';
  
  const isSearchEnabled = participantsFilter === 'search' ? true : false;

  const [{ data, isLoading, isError }, doFetch] = useAgent({ 
    data: {
      happeningAt: {
        day: "",
        timeFrom: "",
        timeTo: ""
      },
      purpose: "",
      category: "",
      link: "",
      participants: [],
      publicNote: "",
      privateNote: ""
    }
  }, `https://outplexperformancemanagement.com/api/meeting/${meetingId}`);

  const [projectData, fetchProjectData] = useAgent({ 
    data: {
      members: []
    }
  }, 
    `https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`);

  const [date, setDate] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [type, setType] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [project, setProject] = useState(currentProjectState._id);
  const [meetingLink, setMeetingLink] = useState(null);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [publicNote, setPublicNote] = useState(null);
  const [privateNote, setPrivateNote] = useState(null);

  const [query, setQuery] = useState('');
  const [confirmRemoval, setConfirmRemoval] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setDate(new Date(data.data.happeningAt.day))
    setTimeFrom(data.data.happeningAt.timeFrom);
    setTimeTo(data.data.happeningAt.timeTo);
    setPurpose(data.data.purpose);
    setType(data.data.category);
    setMeetingLink(data.data.link);
    setSelectedParticipants([...(data.data.participants.map(p => p._id))]);
    setPublicNote(data.data.publicNote);
    setPrivateNote(data.data.privateNote);
  }, [
    data.data.happeningAt.day,
    data.data.happeningAt.timeFrom,
    data.data.happeningAt.timeTo,
    data.data.category,
    data.data.purpose,
    data.data.link,
    data.data.selectedParticipants,
    data.data.publicNote,
    data.data.privateNote
  ])

  function ctaFn (step) {
    switch(step) {
      case 1:
        setCurrentStep(2);
        break;
      case 2:
        break;
    }
  }

  function cancelFn (step) {
    switch(step) {
      case 1:
        break;
      case 2:
        setCurrentStep(1);
        break;
    }
  }

  function updateSelect(action, value) {
    switch(action) {
      case "date": 
        setDate(value);
        setIsDateSelectOpen(false);
        break;
      case "timeFrom":
        setTimeFrom(value);
        setIsTimeFromSelectOpen(false);
        break;
      case "timeTo":
        setTimeTo(value);
        setIsTimeToSelectOpen(false);
        break;
      case "type":
        setType(value);
        setIsTypeSelectOpen(false);
        break;
      case "purpose":
        setPurpose(value);
        setIsPurposeSelectOpen(false);
        break;
      case "project":
        setProject(value);
        setIsProjectSelectOpen(false);
        break;
    }
  }

  function toggleUser(id) {
    if (isUserSelected(id)) {
      const listWithoutCurrentUser = selectedParticipants.filter(userId => userId != id);
      setSelectedParticipants(listWithoutCurrentUser);
    } else {
      setSelectedParticipants([...selectedParticipants, id]);
    }
  }

  function isUserSelected(id) {
    console.log(id);
    console.log(selectedParticipants.includes(id));
    return (selectedParticipants.includes(id))
  }

  function isUserDisabled(id) {
    return (authState.userInfo._id === id);
  }

  async function deleteMeeting() {
    try {
      setSaving(true);

      const result = await axios.delete(`https://outplexperformancemanagement.com/api/meeting/${meetingId}`, {});

        if (result.status === 200) {
          setSaving(false);
          onCancel();
          setPopUpMessage({
            msg: `The meeting was deleted successfully.`,
            style: {
              borderTop: '5px solid #208bc5',
              backgroundImage: 'linear-gradient(-180deg, #fff, #f5f8f9 99%)',
              color: '#0c6493',
              width: '300px',
              top: '120px'
            }
          })
          setTimeout(function() { 
            setPopUpMessage({
              msg: "",
              style: {}
            })
            history.go(window.location.href);
          }, 2000);
        }
        console.log(result);
    } catch (err) {
      setSaving(false);
      console.dir(err);
    }
  }
  async function updateMeeting() {
    try {
      setSaving(true);

      const result = await axios.put(`https://outplexperformancemanagement.com/api/meeting/${meetingId}`, {
        happeningAt: {
          day: date,
          timeFrom,
          timeTo
        },
        purpose: purpose.toLowerCase(),
        category: type.toLowerCase(),
        link: meetingLink,
        participants: selectedParticipants,
        publicNote,
        privateNote
      });

        if (result.status === 200) {
          setSaving(false);
          onCancel();
          setPopUpMessage({
            msg: `The meeting was updated successfully.`,
            style: {
              borderTop: '5px solid #208bc5',
              backgroundImage: 'linear-gradient(-180deg, #fff, #f5f8f9 99%)',
              color: '#0c6493',
              width: '300px',
              top: '120px'
            }
          })
          setTimeout(function() { 
            setPopUpMessage({
              msg: "",
              style: {}
            })
            history.go(window.location.href);
          }, 2000);
        }
        console.log(result);
    } catch (err) {
      setSaving(false);
      console.dir(err);
    }
  }

  return (
    <div className={`${styles['edit-meeting']} ${noOverflow}`}>
      <div className={styles['edit-meeting-header']}>
        <p className={styles['edit-header-name']}>Meeting Details</p>
        <div className={styles['filter-selectors-2']}>
          <div className={styles['selectors']}>
            <FilterOption title="Date & Location" 
              selected={filter === 'details'}
              onClick={() => selectFilter("details")} 
            />
            <FilterOption title="Participants" 
              count={data.data.participants.length}
              selected={filter === 'participants'}
              onClick={() => selectFilter("participants")} 
            />
            <FilterOption title="Notes"
              selected={filter === 'notes'} 
              onClick={() => selectFilter("notes")} 
            />
          </div>
        </div>
      </div>
      <div className={styles['create-meeting-content']}>
        {filter === 'details' &&
          <>
          {isError && <div>Something went wrong ...</div>}
          { 
            isLoading 
            ? ( <Loading color="#0f6695"/> )
            : <>
              <div className={styles['date-time']}>
                <div className={styles['date']}>
                  <p>Date</p>
                  <SelectButton value={formatDate(date)} isOpen={isDateSelectOpen} setIsOpen={setIsDateSelectOpen}>
                    <CalendarDropdown selectedDate={date} onClick={(value) => updateSelect('date', value)} />
                  </SelectButton>
                </div>
                <div className={styles['time']}>
                  <p>Time</p>
                  <div className={styles['from-to']}>
                    <div className={styles['from']}>
                    <SelectButton value={timeFrom} isOpen={isTimeFromSelectOpen} setIsOpen={setIsTimeFromSelectOpen}>
                      <DefaultDropdown centeredText
                        maxHeight="280px"
                        items={times}
                        selectedItem={timeFrom}
                        onClick={(value) => updateSelect('timeFrom', value)}
                      />
                    </SelectButton>
                    </div>
                    <div className={styles['to']}>
                      <SelectButton value={timeTo} isOpen={isTimeToSelectOpen} setIsOpen={setIsTimeToSelectOpen}>
                        <DefaultDropdown centeredText
                          maxHeight="280px"
                          items={times}
                          selectedItem={timeTo}
                          onClick={(value) => updateSelect('timeTo', value)}
                        />
                      </SelectButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['type-purpose']}>
                <div className={styles['type']}>
                  <p>Type</p>
                  <SelectButton value={type} isOpen={isTypeSelectOpen} setIsOpen={setIsTypeSelectOpen}>
                    <DefaultDropdown onClick={(value) => updateSelect('type', value)}
                      items={['(1:1) One on one', 'Triad']}
                      selectedItem={type}
                    />
                  </SelectButton>
                </div>
                <div className={styles['purpose']}>
                  <p>Purpose</p>
                  <SelectButton value={purpose} isOpen={isPurposeSelectOpen} setIsOpen={setIsPurposeSelectOpen}>
                    <DefaultDropdown onClick={(value) => updateSelect('purpose', value)}
                      items={['coaching', 'shadowing']}
                      selectedItem={purpose}
                    />
                  </SelectButton>
                </div>
              </div>
              <div className={styles['location']}>
                <div className={styles['address-link']}>
                  <p>Meeting Link</p>
                  <Input defaultValue={meetingLink} onChange={(e) => setMeetingLink(e.target.value)} placeholder="Type a meeting link" />
                </div>
              </div>
              </>
            }  
          </>
          }
          {filter === 'participants' &&
          <>
            <div className={styles['participants']}>
              <div className={styles['participants-header']}>
              <div className={styles['participants-search-container']}>
              <Input placeholder={`Search for someone within ${currentProjectState.name}`}
                onChange={(e) => setQuery(e.target.value)}
                disabled={!isSearchEnabled} 
              />
              </div>
                <div className={styles['search-or-selected']}>
                  <a onClick={() => setParticipantsFilter('search')} 
                    className={participantsFilter === 'search' ? styles['selected-selector'] : ''}>
                    Search
                  </a>
                  <a onClick={() => setParticipantsFilter('selected')} 
                    className={participantsFilter === 'selected' ? styles['selected-selector'] : ''}>
                    Selected <span>{selectedParticipants.length}</span>
                  </a>
                </div>
              </div>
              
              <div className={styles['search-content']}>
                <div className={styles['filter-content-items']}>
                  {participantsFilter === 'search' &&
                    projectData.data.data.members
                      .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                      .map((participant, index) => {
                        return (
                          <FilterItem key={index} role={`${isUserDisabled(participant._id) ? participant.role + " (You)" : participant.role}`}
                            onCheck={() => toggleUser(participant._id)} 
                            checked={isUserSelected(participant._id)}
                            disabled={isUserDisabled(participant._id)}>
                            <PeopleItem url={participant.url} 
                              name={`${participant.firstName} ${participant.lastName}`}
                            />
                          </FilterItem>
                        )
                    })
                  }
                  {participantsFilter === 'selected' &&
                    projectData.data.data.members
                      .filter(p => isUserSelected(p._id))
                      .map((participant, index) => {
                        return (
                          <FilterItem key={index} role={`${isUserDisabled(participant._id) ? participant.role + " (You)" : participant.role}`}
                            onCheck={() => toggleUser(participant._id)} 
                            checked
                            disabled={isUserDisabled(participant._id)}>
                            <PeopleItem url={participant.url} 
                              name={`${participant.firstName} ${participant.lastName}`}
                            />
                          </FilterItem>
                        )
                      })
                  }
                </div>
              </div>
            </div>
          </>
          }
          {filter === 'notes' &&
          <>
            <div className={styles['notes']}>
              <div>
                <div>
                  <p className={styles['head-text']}>Public Note</p>
                  <p className={styles['visibility-note']}>Visible to agents</p>
                </div>
                <textarea className={styles['public-note']} 
                  defaultValue={publicNote} 
                  onChange={(e) => setPublicNote(e.target.value)}>
                 </textarea>
              </div>
              <div>
                <div>
                  <p className={styles['head-text']}>Private Note</p>
                  <p className={styles['visibility-note']}>Only visible to you</p>
                </div>
                <textarea className={styles['private-note']} 
                  defaultValue={privateNote} 
                  onChange={(e) => setPrivateNote(e.target.value)}>
                 </textarea>
              </div>
            </div>
          </>
          }
      </div>
      <div className={styles['edit-meeting-footer']}>
        {confirmRemoval === true &&
          <>
            Are you sure you want to delete this meeting?
            <div className={styles['create-meeting-buttons']}>
              <Button color="transparent" onClick={() => setConfirmRemoval(false)}>No, forget it.</Button>
              <Button color="primary" onClick={() => deleteMeeting()}>
              {saving
                  ? <Loading color="#fff"/>
                  : "Yes, delete it."
                } 
              </Button>
            </div>
          </>
        }
        {confirmRemoval === false &&
          <>
            <Button color="danger" onClick={() => setConfirmRemoval(true)}>Delete</Button>
            <div className={styles['create-meeting-buttons']}>
              <Button color="transparent" onClick={() => onCancel()}>Cancel</Button>
              <Button color="primary" disabled={selectedParticipants.length < 2} onClick={selectedParticipants.length < 2 ? null : () => updateMeeting()}>
                {saving
                  ? <Loading color="#fff"/>
                  : "Save"
                } 
              </Button>
            </div>
          </>
        }
      </div> 
    </div>
  );
}

export default MeetingDetails;