import { useRef } from 'react';
import { useOutsideClick } from '../../../hooks/dom.js';
import './SelectButton.scss';
import SelectArrow from '../SelectArrow';

function Select({ value, isOpen, setIsOpen, position = 'bottom', children }) {
  const showDropdown = isOpen ? 'select__dropdown--show' : '';
  const isHighlighted  = isOpen ? 'select__button--highlight' : '';
  const selectDropdownStyle = {
    top: position === 'bottom' ? '3.125rem' : '',
    bottom: position === 'top' ? '3.125rem' : ''
  }  

  const dropdownRef  = useRef();

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className='select'>
      <div onClick={() => setIsOpen(!isOpen)} className={`select__button ${isHighlighted}`}>
        <span>{value}</span>
        <SelectArrow isOpen={isOpen} />
      </div>
      <div style={selectDropdownStyle} className={`select__dropdown ${showDropdown}`}>
        {children}
      </div> 
    </div>
  )
}

export default Select;