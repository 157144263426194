import './DefaultDropdown.scss';

function DefaultDropdown ({ items, selectedItem, maxHeight, centeredText = false, onClick }) {
  const addedStyles = {
    textAlign: centeredText ? 'center' : '',
    maxHeight: maxHeight || ''
  }

  function fn(item, index) {
    const isSelected = selectedItem && selectedItem === item;
    
    const modifiers = `
      ${isSelected ? 'default-dropdown__item--selected'  : ''}
    `;

    const setItem = onClick ? () => onClick(item) : null;

    return (
      <div className={`default-dropdown__item ${modifiers}`} 
        key={index} 
        onClick={setItem}
      >{item}
      </div>
    );
  }
  
  return (
    <div style={addedStyles} className='default-dropdown'>
      {items.map(fn)}
    </div>
  )
}

export default DefaultDropdown;