import { useEffect, useCallback } from 'react';

function useOutsideClick(ref, fn) {
  const handleClickOutside = useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      fn();
    }
  }, [ref, fn]);
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  
    return function cleanupListener() {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside]);
}

function useViewport() {
  const setViewport = useCallback(() => {
    if(window.matchMedia('(min-width: 1200px)').matches) {
      const vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setViewport);

    return function cleanupListener() {
      window.removeEventListener('resize', setViewport)
    }
  }, [setViewport]);
}

export {
  useOutsideClick,
  useViewport
}