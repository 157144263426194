import styles from './styles.module.css';
import { useEffect, useState } from 'react';
import Input from '../../Common/Input/';
import Checkbox from '../../Common/Checkbox';
import ProfilePic from '../../Common/ProfilePic';
import { useAgent } from '../../../hooks/agent';
import { useAuth } from '../../../hooks/auth';
import Loading from '../../Common/Loading';
import { useProject } from '../../../hooks/project';

function FilterOption ({ title, count, selected, ...rest }) {
  const isSelected = selected ? styles['filter-option-active'] : '';
  return (
    <a className={`${styles['filter-option']} ${isSelected}`} {...rest}>
      <div>
        <p>{title}</p>
        <div className={styles['filter-option-count']}>{count}</div>
      </div>
    </a>
  )
}

const ProjectItem = ({ title}) => {
  return (
    <p className={styles['project-item']}>{title}</p>
  )
}

const PeopleItem = ({ name, url }) => (
  <div className={styles['people-item']}>
    <ProfilePic width="1.5625rem" height="1.5625rem" url={url} letter={name.substring(0,1)}/>
    <p>{name}</p>
  </div>
);

function FilterItem({role, children, ...rest }) {
  return (
    <div className={styles['filter-item']}>
      <Checkbox {...rest}/>
      {children}
      <p className={styles['filter-item-role']}>{role}</p>
    </div>
  );
}

const getCheckedItems = (selectedItems, items) => {
  const selectedItemsIds = selectedItems.map(i => i._id);
  const newItems = items.map(item => {
    item["checked"] = selectedItemsIds.indexOf(item._id) !== -1 ? true : false;
    return item;
  });

  return newItems;
}

function MeetingFilter({ filter, 
  onSelectFilter, 
  selectedSupervisors = [], 
  selectedManagers = [],
  selectedAgents = [],
  onSelectManager,
  onSelectSupervisor,
  onSelectAgent
}) {
  const { currentProjectState } = useProject();
  const [projectData, fetchProjectData] = useAgent({ 
    data: {
      members: []
    }
  }, 
    `https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`);
  
  const [supervisors, setSupervisors] = useState([]);
  const [managers, setManagers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [query, setQuery] = useState('');
  const [filteredManagers, setFilteredManagers] = useState([]);
  const [filteredSupervisors, setFilteredSupervisors] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);


  useEffect(() => {
    const newManagers = getCheckedItems(selectedManagers, projectData.data.data.members.filter(user => user.role === 'Manager'));
    const newSupervisors = getCheckedItems(selectedSupervisors, projectData.data.data.members.filter(user => user.role === 'Supervisor'));
    const newAgents = getCheckedItems(selectedAgents, projectData.data.data.members.filter(user => user.role === 'Agent'));
  
    setSupervisors(newSupervisors);
    setManagers(newManagers);
    setAgents(newAgents);
  }, [
    projectData.data.data.members, 
    selectedManagers,
    selectedSupervisors,
    selectedAgents
  ])

  const selectFilter = (filter) => {
    setQuery("");
    onSelectFilter(filter);
  }

  useEffect(() => {
    let list = [];
    let setList;

    if (filter === 'agent') {
      list = [...agents];
      setList = setFilteredAgents;
    } else if (filter === 'manager') {
      list = [...managers];
      setList = setFilteredManagers;
    } else if (filter === 'supervisor') {
      list = [...supervisors];
      setList = setFilteredSupervisors;
    }

    const lowcQuery = query.toLowerCase();
    const newList = list.filter(item => {
      const nameIncludesQuery = item.firstName.toLowerCase().indexOf(lowcQuery) !== -1;
      return nameIncludesQuery;
    });
    setList(newList);
  }, [filter, query, agents, managers, supervisors]);
  
  return (
    <div className={styles['meeting-filter']}>
      <div className={styles['filter-selectors']}>
        <div className={styles['selectors']}>
          <FilterOption title="Operations Managers" 
            count={selectedManagers.length}  
            selected={filter === 'manager'}
            onClick={() => selectFilter("manager")} 
          />
          <FilterOption title="Supervisors" 
            count={selectedSupervisors.length} 
            selected={filter === 'supervisor'} 
            onClick={() => selectFilter("supervisor")} 
          />
          <FilterOption title="Agents" 
            count={selectedAgents.length} 
            selected={filter === 'agent'} 
            onClick={() => selectFilter("agent")} 
          />
        </div>
      </div>
      <div className={styles['filter-inputs']}>
        <Input value={query}
          placeholder={`Search for a ${filter}`}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className={styles['filter-content']}>
        <div className={styles['filter-content-items']}>
          { filter === 'agent' && 
            filteredAgents.map((a, index) => {
              return (
                <FilterItem key={index} 
                  role="Agent" 
                  onCheck={() => onSelectAgent(a)} 
                  checked={a.checked}
                >
                  <PeopleItem url={a.profilePicUrl} name={`${a.firstName} ${a.lastName}`} />
                </FilterItem>
              )
            })
          }
          { filter === 'manager' &&
            filteredManagers.map((m, index) => (
              <FilterItem key={index} 
                role="Ops. Manager" 
                onCheck={() => onSelectManager(m)} 
                checked={m.checked}
              >
                <PeopleItem url={m.profilePicUrl} name={`${m.firstName} ${m.lastName}`} />
              </FilterItem>
            ))
          }
          { filter === 'supervisor' && 
            filteredSupervisors.map((s, index) => (
              
              <FilterItem key={index} 
                role="Supervisor" 
                onCheck={() => onSelectSupervisor(s)} 
                checked={s.checked}
              >
                <PeopleItem url={s.profilePicUrl} name={`${s.firstName} ${s.lastName}`} />
              </FilterItem>
            ))
          } 
        </div>
      </div>
    </div>
  )
}

export default MeetingFilter;