import "./Projects.scss";
import ProjectItem from "./Content/ProjectItem.js";
import PeopleItem from '../People/Content/PeopleItem.js';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '../Common/Button';
import Input from '../Common/Input';
import Modal from '../Common/Modal';
import CreateProject from './Modals/CreateProject';
import CreatePeople from './Modals/CreatePeople';
import ManageProject from './Modals/ManageProject';
import MeetingSession from '../Meetings/Content/MeetingSession';
import { useAgent } from '../../hooks/agent.js';
import { useAuth } from '../../hooks/auth';
import { useProject } from '../../hooks/project';
import Loading from '../Common/Loading'
import Alert from '../Common/Alert'
import axios from "axios";


import { ReactComponent as UsersIcon } from '../../assets/icons/filled/users.svg';
import { ReactComponent as UserAddIcon } from '../../assets/icons/filled/useradd.svg';
import { ReactComponent as ProjectAddIcon } from '../../assets/icons/project-add.svg';


function Sidebar ({ children }) {
  return (
    <div className='projects__sidebar'>
      {children}
    </div>
  );
}

function Content ({ children }) {
  return (
    <div className='projects__content'>
      {children}
    </div>
  );
}

function PeopleSelect({ name, count, selected, onClick }) {
  const withCount = count > 0 ? `( ${count} )` : '';
  
  return (
    <div className="projects__select" onClick={onClick}>
      <a className={`${ selected ? 'projects__select--selected' : ''}`}>
        {name} {withCount}
      </a>
    </div>
  )
}

function Projects () {
  const { authState } = useAuth();
  const history = useHistory();
  const [view, setView] = useState('projects');
  const [createProject, setCreateProject] = useState(false);
  const [createPeople, setCreatePeople] = useState(false);
  const [manageProject, setManageProject] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [{ data, isLoading, isError }, doFetch] = useAgent(
    { data: [] }, 
    "https://outplexperformancemanagement.com/api/project");

  const [popUpMessage, setPopUpMessage] = useState({
    msg: '',
    style: {}
  });

  const [countData, fetchCount] = useAgent({ 
    data: {
      projectCount: null,
      userCount: null
  }}, 
    "https://outplexperformancemanagement.com/api/general-count/");

  function updateView(view) {
    setView(view);
    if (view === 'projects') {
      doFetch(`https://outplexperformancemanagement.com/api/project`)
    } else {
      doFetch(`https://outplexperformancemanagement.com/api/user`)
    }
  }

  useEffect(() => {
    setProjectInfo({
      name: '', 
      id: null
    })
  }, [])

  
  const [query, setQuery] = useState('');
  const { setProjectInfo } = useProject();

  function selectProject(name, id, url) {
    setProjectInfo({ name, id });
    history.push(url);
  }

  function isUserInProject(projectMembers) {
    return projectMembers.includes(authState.userInfo._id);
  }

  return (
    <div className="projects">
      <Content>
        <div className="projects__header">
          <div className="projects__selector">
            <MeetingSession name="Projects" count={countData.data.data.projectCount || 0} selected={view === 'projects'} onClick={() => updateView('projects')} />
            <MeetingSession name="People" count={countData.data.data.userCount || 0} selected={view === 'people'} onClick={() => updateView('people')} />
            <div className="projects__buttons">
              {authState.userInfo.role != 'Agent' &&
              <>
                 <Button
                  color="gradient" 
                  onClick={() => setCreatePeople(true)}>
                  <UserAddIcon /> Invite to OutPLEX
                </Button>
                <Button
                  color="primary" 
                  onClick={() => setCreateProject(true)}>
                  <ProjectAddIcon /> Create a project
                </Button>
              </>
              }          
            </div>
          </div>
          <Input value={query} 
            placeholder="Search for a project"
            onChange={(e) => setQuery(e.target.value)} 
          />
        </div>
        <div className="projects-list">
            {isError && <div>Something went wrong ...</div>}
            { 
              isLoading 
              ? ( <Loading color="#0f6695"/>)
              : view === 'projects'
                ? <>
                    {
                      data.data.length === 0
                      ? <div className='projects-list-empty'>There aren't any projects just yet.</div>
                      : <>
                          {
                            data.data
                            .filter(d => isUserInProject(d.members || []))
                            .map((d, index) => {
                              const url = `/project/${d._id ? d._id.toLowerCase() : ''}/meetings`;
                              return (
                                <ProjectItem to={url} key={index} name={d.name}
                                  onClick={() => selectProject(d.name, d._id, url)}
                                  picUrl='https://i.ibb.co/RjP9Rd7/profile-pic.jpg' />
                              )
                            })
                          }
                          {
                            data.data
                            .filter(d => !isUserInProject(d.members || []))
                            .map((d, index) => {
                              return (
                                <ProjectItem key={index} name={d.name}
                                  picUrl='https://i.ibb.co/RjP9Rd7/profile-pic.jpg' 
                                  disabled />
                              )
                            })
                          }
                        </>
                    }
                  </>
                : (data.data.map((user, index) => {
                    return (
                      <PeopleItem key={index} name={user.firstName || ''} lastName={user.lastName}
                        role={user.role}
                        picUrl={user.url}
                      />
                    )
                  }))
            }
        </div>
        <Modal show={createProject} onHide={() => setCreateProject(false)}>
          {createProject && <CreateProject onCancel={() => setCreateProject(false)} setPopUpMessage={setPopUpMessage} />}
        </Modal>
        <Modal show={createPeople} onHide={() => setCreatePeople(false)}>
          {createPeople && <CreatePeople onCancel={() => setCreatePeople(false)} setPopUpMessage={setPopUpMessage} />}
        </Modal>
        <Modal show={manageProject} onHide={() => setManageProject(false)}>
          {manageProject && <ManageProject id={selectedTeam}/>}
        </Modal>
        {popUpMessage.msg && <Alert msg={popUpMessage.msg} style={popUpMessage.style}/>}
      </Content>
    </div>
  )
}

export { Projects }