import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';

function KPICard() {
  const canvasRef = useRef();

  useEffect(() => {
    const myChart = new Chart(canvasRef.current, {
      type: 'line',
      data: {
        labels: ['May 12', 'May 13', 'May 14', 'May 15', 'May 16', 'May 17'],
        datasets: [{
          label: 'May',
          data: [67, 19, 39, 3, 2, 82],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 3,
        }, {
          label: 'June',
          data: [8, 22, 0, 99, 88, 1],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 3
        }]
      },
      options: {
        elements: {
          line: {
            tension: 0.4
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              display: false
            },
            title: {
              display: true,
              text: 'Higher is better',
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 50
            },
            min: 0,
            max: 100,
            beginAtZero: true,
            title: {
              display: true,
              text: 'Scale'
            }
          }
        }
      }
    });
    return function cleanup () {
      myChart.destroy();
    }
  }, [])

  return (
    <div>
      <canvas ref={canvasRef} width="300" height="300"></canvas>
    </div>
  )
}

export default KPICard;