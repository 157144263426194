import { createContext, useState, useContext } from 'react';

const ProjectContext = createContext();
const { Provider } = ProjectContext;

function useProject() {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error(`useProject must be used within ProjectProvider`);
  }
  return context;
}

const ProjectProvider = ({ children }) => {
  const [currentProjectState, setCurrentProjectState] = useState({
    name: null,
    id: null,
  });

  const setProjectInfo = ({ name, id }) => {
    setCurrentProjectState({ name, id });
  };

  return (
    <Provider
      value={{
        setProjectInfo,
        currentProjectState,
      }}
    >
      {children}
    </Provider>
  );
};

export { ProjectProvider, useProject };