import { useRef } from 'react';
import { useOutsideClick } from '../../hooks/dom.js';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { ReactComponent as AddIcon } from "../../assets/icons/plus.svg";

function CreateDropdown({isOpen, setIsOpen, children}) {
  const dropdownRef = useRef();
  const dropdownStyle = { display: isOpen ? 'block' : 'none' }

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className={styles['create-button-dropdown']} >
      <div onClick={() => setIsOpen(!isOpen)} className={`${styles['create-button']} ${isOpen ? styles['create-button-active'] : ''}`}>
        <AddIcon />
      </div>
      <div style={dropdownStyle} className={styles['dropdown-container']}>
        <p>Quick Create</p>
        <div className={styles['dropdown-options']}>
          {children}
        </div>
      </div>
    </div>
  );
}

const CreateOption = ({ title, to, icon: Icon, onSelect }) => (
  <Link onClick={() => onSelect(false)} to={to} className={styles['create-button-option']}>
    <Icon />
    <p>{title}</p>
  </Link>
);

export {
  CreateDropdown,
  CreateOption
}