import './CreatePeople.scss';
import styles from './CreateProject.module.css';
import { useEffect, useRef, useState } from 'react';

import Button from '../../Common/Button';
import Input from '../../Common/Input/';
import Checkbox from '../../Common/Checkbox/';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import ProfilePic from '../../Common/ProfilePic/';
import { useAgent } from '../../../hooks/agent';
import { useAuth } from '../../../hooks/auth';
import Loading from '../../Common/Loading'
import axios from 'axios';

const PeopleItem = ({ name, url }) => (
  <div className={styles['people-item']}>
    <ProfilePic width="1.5625rem" height="1.5625rem" url={url} letter={name.substring(0, 1)}/>
    <p>{name}</p>
  </div>
);

function FilterItem({ role, note, children, ...rest }) {
  const color = note.color === 'green' ? "#07780c" : "#cd1c0f";
  
  return (
    <div className={styles['filter-item']}>
      <Checkbox {...rest}/>
      {children}
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        {note && 
          <p style={{ color: color, marginRight: `${role ? '20px' : ''}` }} className={styles['filter-item-role']}>
            {note.msg}
          </p>
        }
        <p className={styles['filter-item-role']}>{role}</p>
      </div>
    </div>
  );
}

function RoleOption ({ name, selected = false, ...rest }) {
  const classes = `
    create-people__role-option
    ${selected ? 'create-people__role-option--selected ' : ''}
  `;
  
  return (
    <span className={classes} {...rest}>{name}</span>
  );
}

function EmailItem ({ email, onRemove }) {
  const classes = `
    create-people__email-item
  `;

  return (
    <div className={classes}>
      {email}
      <span onClick={onRemove}>
        <CloseIcon />
      </span>
    </div>
  )
}

function CreatePeople({ onCancel, setPopUpMessage }) {
  const { authState } = useAuth();
  const [inviting, setInviting] = useState(false);
  const peopleContainerRef = useRef();
  const [selectedRole, setSelectedRole] = useState('Agent');
  const [emails, setEmails] = useState([]);
  const emailInputRef = useRef();
  const [query, setQuery] = useState('');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [usersFilter, setUsersFilter] = useState('team');
  const [projectsData, ] = useAgent({ 
    data: []
  }, `https://outplexperformancemanagement.com/api/project`);

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  function removeFromEmailList (emailIndex) {
    const newEmailList = emails.filter((_, index) => index != emailIndex);
    setEmails([...newEmailList]);
    emailInputRef.current.focus();
  }

  function updateEmailList (e) {
    const pressedKey = e.key || e.keyCode; // if .key property is not supported, switch to keyCode
    let email = e.target.value;

    if (pressedKey === 188
      || pressedKey === 32
      || pressedKey === 13
      || pressedKey === ","
      || pressedKey === " "
      || pressedKey === "Enter"
    ) {
      if (pressedKey != 'Enter' && pressedKey != 13) {
        email = e.target.value.substring(0, e.target.value.length - 1);
      }

      if (email != '' 
        && email != ',' 
        && emails.find(e => e === email) === undefined
      ) {
        setEmails([...emails, email]);
      }

      e.target.value = '';
    }   
  }

  function toggleProject(id) {
    if (isProjectSelected(id)) {
      const listWithoutSelectedProject = selectedProjects.filter(projectId => projectId != id);
      setSelectedProjects(listWithoutSelectedProject);
    } else {
      setSelectedProjects([...selectedProjects, id]);
    }
  }

  function isProjectSelected(id) {
    return (selectedProjects.includes(id));
  }
  function isProjectDisabled(projectMembers) {
    return !(projectMembers.includes(authState.userInfo._id))
  }

  async function invitePeople() {
    try {
      setInviting(true);
      const result = await axios.post('https://outplexperformancemanagement.com/api/invite', {
        emails,
        role: selectedRole,
        invitedBy: authState.userInfo._id,
        projectsToAddTo: selectedProjects
      });

        if (result.status === 201) {
          setInviting(false);
          onCancel();
          setPopUpMessage({
            msg: "Invitation successful. Invitees must now set up their account.",
            style: {
              borderTop: '5px solid #208bc5',
              backgroundImage: 'linear-gradient(-180deg, #fff, #f5f8f9 99%)',
              color: '#0c6493',
              width: '300px',
              top: '120px'
            }
          })
          setTimeout(function() { 
            setPopUpMessage({
              msg: "",
              style: {}
            })
          }, 5000);
        }
        console.log(result);
    } catch (err) {
      setInviting(false);
      console.dir(err);
    }
  }

  return (
    <div ref={peopleContainerRef} className={`create-people`}>
      <div className='create-people__header'>
        <p>Invite users to OutPLEX</p>
      </div>
      <div className='create-people__body'>
        <div className="create-people__input">
          <p className="create-people__input-label">Choose the role of your invitees</p>
          <div className="create-people__project-select">
            <RoleOption name="Agent" selected={selectedRole === 'Agent'} onClick={() => setSelectedRole("Agent")} />
            <RoleOption name="Supervisor" selected={selectedRole === 'Supervisor'} onClick={() => setSelectedRole("Supervisor")} />
            <RoleOption name="Manager" selected={selectedRole === 'Manager'} onClick={() => setSelectedRole("Manager")} />
          </div>
        </div>
        <div className="create-people__input">
          <p style={{
            marginTop: '15px',
            marginBottom: '15px'
          }} className="create-people__input-label">Type in their email addresses</p>
          <div className="create-people__email-list">
            {
              emails.map((email, index) => {
                return <EmailItem key={index} email={email} onRemove={() => removeFromEmailList(index)}/>
              })
            }
            <input ref={emailInputRef} className="create-people__email-input" placeholder="Start typing here" onKeyUp={(e) => updateEmailList(e)} />
          </div>
        </div>
        <div className={styles['participants']}>
          <p style={{
            marginTop: '15px',
            marginBottom: '15px'
          }}>Choose the projects they'll get access to (Optional)</p>
          <div className={styles['search-content-2']}>
            <div className={styles['filter-content-items']}>
            {projectsData.isError && <div>Something went wrong ...</div>}
            { usersFilter === 'team' && 
              (projectsData.isLoading 
                ? ( <Loading color="#0f6695"/> )
                : (projectsData.data.data
                  .map((project, index) => {
                    const isDisabled = isProjectDisabled(project.members);
                    const isSelected = isProjectSelected(project._id);
                    return (
                      <FilterItem key={index}
                        role={`${isDisabled ? 'Cannot invite here' : ''}`}
                        note={{ 
                          color: 'green', 
                          msg: `${isSelected ? 'Access allowed' : ''}`
                        }}
                        onCheck={() => toggleProject(project._id)} 
                        checked={isSelected} 
                        disabled={isDisabled}>
                        <PeopleItem url={null} 
                          name={`${project.name}`}
                        />
                      </FilterItem>
                    )
                  }))
              )
            }
            </div>
          </div>
        </div>
        <div className='create-people__footer'>
          <p>Your invitees will recieve an email with further instructions to set up their account.</p>
          <Button style={{ 
            minWidth: '120px',
            maxHeight: '40px',
            height: '40px'
          }} color="primary" onClick={() => invitePeople()}>
            {inviting
              ? <Loading color="#fff"/>
              : 'Send Invites'
            } 
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreatePeople;