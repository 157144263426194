import './PeopleItem.scss';
import ProfilePic from '../../Common/ProfilePic/';
import { ReactComponent as MeetingIcon } from '../../../assets/icons/meeting.svg';
import { ReactComponent as PerformanceIcon } from '../../../assets/icons/performance.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/project.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/icons/feedback.svg';
import { Link } from 'react-router-dom';

function PeopleItem({ picUrl, name, lastName, role, supervisedBy, ...rest }) {
  /* Remember to add links for meeting, teams, performance and feedback. */
  return (
    <div className="people-item" {...rest}>
      <div className="people-item__metadata">
        <div>
          <ProfilePic width="40px" height="40px" url={picUrl} letter={name.substring(0,1)}/>
          <div>
            <p>{name} {lastName}</p>
            <p>{role}</p>
          </div>
        </div>
      </div>
      <div className="people-item__links">
        <Link>
          <MeetingIcon />
          <span>Meetings</span>
        </Link>
        <Link>
          <PerformanceIcon />
          <span>Performance</span>
        </Link>
        <Link>
          <TeamIcon />
          <span>Project</span>
        </Link>
        <Link>
          <FeedbackIcon />
          <span>Feedback</span>
        </Link>
      </div>
    </div>
  )
}

export default PeopleItem;