import styles from './CreateTeam.module.css';
import { useEffect, useRef, useState } from 'react';
import Input from '../../Common/Input';
import Checkbox from '../../Common/Checkbox';
import Button from '../../Common/Button';
import ProfilePic from '../../Common/ProfilePic';
import { useAgent } from '../../../hooks/agent';
import { useProject } from '../../../hooks/project';
import { useAuth } from '../../../hooks/auth';
import Loading from '../../Common/Loading'
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const PeopleItem = ({ name, url }) => (
  <div className={styles['people-item']}>
    <ProfilePic width="1.5625rem" height="1.5625rem" url={url} letter={name.substring(0,1)}/>
    <p>{name}</p>
  </div>
);

function FilterItem({ role, note, children, ...rest }) {
  const color = note.color === 'green' ? "#07780c" : "#cd1c0f";
  
  return (
    <div className={styles['filter-item']}>
      <Checkbox {...rest}/>
      {children}
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        {note && 
          <p style={{ color: color, marginRight: '20px' }} className={styles['filter-item-note']}>
            {note.msg}
          </p>
        }
        <p className={styles['filter-item-role']}>{role}</p>
      </div>
      
    </div>
  );
}

function ManageTeam({ id, onCancel, setPopUpMessage}) {
  const meetingContainerRef = useRef();
  const [usersFilter, setUsersFilter] = useState('team');
  const [saving, setSaving] = useState(false);
  const { authState } = useAuth();
  const { currentProjectState } = useProject();
  const history = useHistory()

  const [projectData, ] = useAgent({ 
    data: {
      id: '',
      name: 'Loading...',
      members: []
    }
  }, `https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`);

  const [usersData, doFetch] = useAgent({ 
    data: []
  }, `https://outplexperformancemanagement.com/api/user`);

  const [name, setName] = useState('');
  const [query, setQuery] = useState('');
  const [inTeamList, setInTeamList] = useState([]);
  const [notInTeamList, setNotInTeamList] = useState([]);
  
  function toggleUser(id, where) {
    let list = where === 'within' ? [...inTeamList] : [...notInTeamList];
    const notes = {
      within: {
        checkedNote: '',
        uncheckedNote: 'will be removed'
      },
      outside: {
        checkedNote: 'will be added',
        uncheckedNote: ''
      }
    }
    
    list = list.map(user => {
      if (user._id === id) {
        user.checked = !user.checked;
        user.note = user.checked ? notes[where].checkedNote : notes[where].uncheckedNote;
      }
      return user;
    })

    where === 'within' ? setInTeamList([...list]) : setNotInTeamList([...list]);
  }

  function isUserInTeam(id) {
    return (projectData.data.data.members.find(user => id === user._id) != undefined)
  }

  useEffect(() => {
    setName(projectData.data.data.name);
  }, [projectData.data.data.name])

  useEffect(() => {
    const within = projectData.data.data.members.map(user => {
      return { ...user, 
        checked: true, 
        note: ''
      }
    })
    const notIn = usersData.data.data
      .filter(user => !isUserInTeam(user._id))
      .map(user => {
        return { ...user, 
          checked: false, 
          note: ''}
      })
    setInTeamList([...within]);
    setNotInTeamList([...notIn]);
  }, [projectData.data.data.members, usersData.data.data])

  async function updateMembers() {
    try {
      setSaving(true);
      
      const selectedUsers = [
        ...inTeamList.filter(user => user.checked),
        ...notInTeamList.filter(user => user.checked)
      ]

      const result = await axios.put(`https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`, {
        name,
        kpis: [],
        members: selectedUsers
      });

        if (result.status === 200) {
          setSaving(false);
          onCancel();
          setPopUpMessage({
            msg: `Changes were saved successfully.`,
            style: {
              borderTop: '5px solid #208bc5',
              backgroundImage: 'linear-gradient(-180deg, #fff, #f5f8f9 99%)',
              color: '#0c6493',
              width: '300px',
              top: '120px'
            }
          })
          setTimeout(function() { 
            setPopUpMessage({
              msg: "",
              style: {}
            })
            history.go(window.location.href);
          }, 2000);
        }
        console.log(result);
    } catch (err) {
      setSaving(false);
      console.dir(err);
    }
  }

  function isUserDisabled(id) {
    return (authState.userInfo._id === id);
  }

  return (
    <div ref={meetingContainerRef} className={`${styles['create-meeting']}`}>
      <div className={styles['create-meeting-header']}>
        <p className={styles['header-name']}>Manage {projectData.data.name} members</p>
      </div>
      <div className={styles['create-meeting-content']}>
        <div className={styles['participants']}>
          <p>Toggle checkbox to add or remove</p>
          <div className={styles['participants-header']}>
            <div className={styles['participants-search-container']}>
              <Input placeholder={`Search for someone within ${ usersFilter === 'team' ? 'this project' : 'the system'}`}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className={styles['search-or-selected']}>
              <a onClick={() => setUsersFilter('team')} 
                className={usersFilter === 'team' ? styles['selected-selector'] : ''}>
                Within <span>{inTeamList.length}</span>
              </a>
              <a onClick={() => setUsersFilter('noteam')} 
                className={usersFilter === 'noteam' ? styles['selected-selector'] : ''}>
                Outside <span>{notInTeamList.length}</span>
              </a>
            </div>
          </div>
          <div className={styles['search-content']}>
            <div className={styles['filter-content-items']}>
            {projectData.isError && <div>Something went wrong ...</div>}
            { usersFilter === 'team' && 
              (projectData.isLoading 
                ? ( <Loading color="#0f6695"/> )
                : (inTeamList
                  .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                  .map((user, index) => {
                    return (
                      <FilterItem key={index} role={user.role} note={{ color: 'red', msg: user.note}}
                        onCheck={() => toggleUser(user._id, 'within')} 
                        checked={user.checked}
                        disabled={isUserDisabled(user._id)}>
                        <PeopleItem url={user.url}
                          name={`${user.firstName} ${user.lastName}`}
                        />
                      </FilterItem>
                    )
                  }))
              )
            }
            { usersFilter === 'noteam' &&
              ( notInTeamList
                .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                .map((user, index) => {
                  return (
                    <FilterItem key={index} role={user.role} note={{ color: 'green', msg: user.note}}
                      onCheck={() => toggleUser(user._id, 'outside')} 
                      checked={user.checked}
                      disabled={isUserDisabled(user._id)}>
                      <PeopleItem url={user.url} 
                        name={`${user.firstName} ${user.lastName}`}
                      />
                    </FilterItem>
                )})
              )
            }
            </div>
          </div>
        </div>
      </div>
      <div className={styles['create-meeting-footer']}>
        <div className={styles['create-meeting-buttons']}>
          <Button color="transparent"onClick={() => onCancel()}>Cancel</Button>
          <Button style={{ 
            minWidth: '120px',
            maxHeight: '40px',
            height: '40px'
          }} color="primary" onClick={() => updateMembers()}>
            {saving
              ? <Loading color="#fff"/>
              : 'Save Changes'
            } 
          </Button>
        </div>
      </div> 
    </div>
  );
}

export default ManageTeam;