import "./Teams.scss";
import TeamItem from "./Content/TeamItem.js";
import { useEffect, useState } from 'react';
import Button from '../Common/Button';
import Input from '../Common/Input';
import Modal from '../Common/Modal';
import CreatePeople from './Modals/CreateTeam';
import ManageTeam from './Modals/ManageTeam';
import { ReactComponent as UsersIcon } from '../../assets/icons/filled/users.svg';
import { ReactComponent as UserAddIcon } from '../../assets/icons/filled/useradd.svg';
import { useAgent } from '../../hooks/agent.js';
import { useProject } from '../../hooks/project.js';
import { useParams } from "react-router";
import Loading from '../Common/Loading'
import Alert from '../Common/Alert';

function Sidebar ({ children }) {
  return (
    <div className='teams__sidebar'>
      {children}
    </div>
  );
}

function Content ({ children }) {
  return (
    <div className='teams__content'>
      {children}
    </div>
  );
}

function PeopleSelect({ name, count, selected, onClick }) {
  const withCount = count > 0 ? `( ${count} )` : '';
  
  return (
    <div className="teams__select" onClick={onClick}>
      <a className={`${ selected ? 'teams__select--selected' : ''}`}>
        {name} {withCount}
      </a>
    </div>
  )
}

function Teams () {
  const { currentProjectState } = useProject();
  const [view, setView] = useState('people');
  const [createPeople, setCreateTeam] = useState(false);
  const [manageTeam, setManageTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [popUpMessage, setPopUpMessage] = useState({
    msg: '',
    style: {}
  });
  const [teams, setTeams] = useState([{
    id: 1,
    name: "Tonya's Team",
    count: 9
  }, {
    id: 2,
    name: "Maxim's Team",
    count: 5
  }, {
    id: 3,
    name: "Joel's Team",
    count: 4
  }, {
    id: 4,
    name: "Luciano's Team",
    count: 7
  }, {
    id: 5,
    name: "Bertha's Team",
    count: 2
  }]);
  const [yourTeams, setYourTeams] = useState([{
      id: 2,
      name: "Maxim's Team",
      count: 5
    }
  ])
  const [{ data, isLoading, isError }, doFetch] = useAgent(
    { data: [] }, 
    `https://outplexperformancemanagement.com/api/team?project=${currentProjectState.id}`);
  const [query, setQuery] = useState('');

  function selectTeam(id) {
    setSelectedTeam(id);
    setManageTeam(true)
  }

  useEffect(() => {
    doFetch(`https://outplexperformancemanagement.com/api/team?project=${currentProjectState.id}`);
  }, [currentProjectState.id])

  return (
    <div className="teams">
      <Content>
        <div className="teams__header">
          <div className="teams__selector">
            <div className="teams__buttons">
              <Button
                color="primary" 
                onClick={() => setCreateTeam(true)}>
                <UserAddIcon /> Create a team
              </Button>
            </div>
          </div>
          <Input value={query} 
            placeholder="Search for a specific team"
            onChange={(e) => setQuery(e.target.value)} 
          />
        </div>
        <div className="teams-list">
            {isError && <div>Something went wrong ...</div>}
              { 
                isLoading 
                ? ( <Loading color="#0f6695"/> )
                : (data.data.map((team, index) => {
                  return (
                    <TeamItem key={index} name={team.name} lastName=""
                      onClick={() => selectTeam(team._id)} 
                      role="Agent"
                      picUrl={team.url}
                      supervisedBy="Barack"/>
                  )
                }))
              }
        </div>
        <Modal show={createPeople} onHide={() => setCreateTeam(false)}>
          {createPeople && <CreatePeople onCancel={() => setCreateTeam(false)} setPopUpMessage={setPopUpMessage}/>}
        </Modal>
        <Modal show={manageTeam} onHide={() => setManageTeam(false)}>
          {manageTeam && <ManageTeam id={selectedTeam} onCancel={() => setManageTeam(false)} setPopUpMessage={setPopUpMessage}/>}
        </Modal>
        {popUpMessage.msg && <Alert msg={popUpMessage.msg} style={popUpMessage.style}/>}
      </Content>
    </div>
  )
}

export { Teams }