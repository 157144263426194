import styles from '../styles.module.css';
import Chart from 'chart.js/auto';
import { useEffect, useRef, useState } from 'react';

function FilterOption ({ title, count, selected, ...rest }) {
  const isSelected = selected ? styles['filter-option-active'] : '';
  return (
    <a className={`${styles['filter-option']} ${isSelected}`} {...rest}>
      <div>
        <p>{title}</p>
        {count && <div className={styles['filter-option-count']}>{count}</div>}
      </div>
    </a>
  )
}

function PIPForm() {
  const oneRef = useRef();
  const twoRef = useRef();
  const threeRef = useRef();
  const resultRef = useRef();
  const [option, selectOption] = useState('week-1');

  useEffect(() => {
    const oneChart = new Chart(oneRef.current, {
      type: 'bar',
      data: {
        labels: ['CSAT', 'Effort', 'ACL'],
        datasets: [{
          label: '',
          data: [25, 40, 11],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawOnChartArea: false
            }
          }],
          x: {
            ticks: {
              display: false
            },
            title: {
              display: true,
              text: 'Higher is better',
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 50
            },
            min: 0,
            max: 100,
            beginAtZero: true,
            title: {
              display: false,
              text: 'Scale'
            }
          }
        }
      }
    });

    const twoChart = new Chart(twoRef.current, {
      type: 'bar',
      data: {
        labels: ['CSAT', 'Effort', 'ACL'],
        datasets: [{
          label: '',
          data: [25, 40, 11],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawOnChartArea: false
            }
          }],
          x: {
            ticks: {
              display: false
            },
            title: {
              display: true,
              text: 'Higher is better',
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 50
            },
            min: 0,
            max: 100,
            beginAtZero: true,
            title: {
              display: false,
              text: 'Scale'
            }
          }
        }
      }
    });

    const threeChart = new Chart(threeRef.current, {
      type: 'bar',
      data: {
        labels: ['CSAT', 'Effort', 'ACL'],
        datasets: [{
          label: '',
          data: [25, 40, 11],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawOnChartArea: false
            }
          }],
          x: {
            ticks: {
              display: false
            },
            title: {
              display: true,
              text: 'Higher is better',
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 50
            },
            min: 0,
            max: 100,
            beginAtZero: true,
            title: {
              display: false,
              text: 'Scale'
            }
          }
        }
      }
    });

    const resultChart = new Chart(resultRef.current, {
      type: 'bar',
      data: {
        labels: ['CSAT', 'Effort', 'ACL'],
        datasets: [{
          label: '',
          data: [25, 40, 11],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawOnChartArea: false
            }
          }],
          x: {
            ticks: {
              display: false
            },
            title: {
              display: true,
              text: 'Higher is better',
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 50
            },
            min: 0,
            max: 100,
            beginAtZero: true,
            title: {
              display: false,
              text: 'Scale'
            }
          }
        }
      }
    });

    return function cleanup () {
      oneChart.destroy();
      twoChart.destroy();
      threeChart.destroy();
      resultChart.destroy();
    }
  }, [option])

  return (
    <div>
      <div className={styles['selectors']}>
        <FilterOption title="Week 1" 
          selected={option === 'week-1'}
          onClick={() => selectOption("week-1")} 
        />
        <FilterOption title="Week 2" 
          selected={option === 'week-2'}
          onClick={() => selectOption("week-2")} 
        />
        <FilterOption title="Week 3" 
          selected={option === 'week-3'}
          onClick={() => selectOption("week-3")} 
        />
        <FilterOption title="Result" 
          selected={option === 'result'}
          onClick={() => selectOption("result")} 
        />
      </div>
      <div className={styles['create-meeting-content']}>
        {option === 'week-1' &&
          <>
            <div className={styles['shadow-stats']}>
              <div>
                <p>Stats</p>
                <canvas ref={oneRef} width="150" height="100"></canvas>
              </div>
              <div>
                <p className={styles['head-text']}>Notes</p>
                <div className={styles['pip-note']} contentEditable={true}>Hola</div>
              </div>
            </div>
          </>
        }
        {option === 'week-2' &&
          <>
            <div className={styles['shadow-stats']}>
              <div>
                <p>Stats</p>
                <canvas ref={oneRef} width="150" height="100"></canvas>
              </div>
              <div>
                <p className={styles['head-text']}>Notes</p>
                <div className={styles['pip-note']} contentEditable={true}>Hola</div>
              </div>
            </div>
          </>
          }
        {option === 'week-3' &&
          <>
            <div className={styles['shadow-stats']}>
              <div>
                <p>Stats</p>
                <canvas ref={oneRef} width="150" height="100"></canvas>
              </div>
              <div>
                <p className={styles['head-text']}>Notes</p>
                <div className={styles['pip-note']} contentEditable={true}>Hola</div>
              </div>
            </div>
          </>
        }
        {option === 'result' &&
          <>
            <div className={styles['shadow-stats']}>
              <div>
                <p>Stats</p>
                <canvas ref={oneRef} width="150" height="100"></canvas>
              </div>
              <div>
                <p className={styles['head-text']}>Summary</p>
                <div style={{ border: '1px solid black'}} className={styles['pip-note']} contentEditable={true}>
                  <p>CSAT increased by 5%.</p>
                  <p>Effort decreased by 20%.</p>
                  <p>ACL decreased by 10%.</p>

                  Agent did not meet all goals.                   
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default PIPForm;