import { useAuth } from "../hooks/auth.js";
import AuthenticatedApp from './AuthenticatedApp.js';
import { Route, Redirect } from 'react-router-dom';

function AuthenticatedRoute ({ children, ...rest }) {
  const { isAuthenticated } = useAuth();
  
  return (
    <Route {...rest}
      render={() =>
        isAuthenticated() ? (
          <AuthenticatedApp>
            {children}
          </AuthenticatedApp>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AuthenticatedRoute;