import './Login.scss';
import { useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import axios from "axios";
import Joi from 'joi';
import Loading from '../../components/Common/Loading';
import Alert from '../Common/Alert';
import { useAgent } from '../../hooks/agent';

function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

const resetPasswordSchema = Joi.object({
  password: Joi.string()
    .alphanum()
    .min(3)
    .max(30),
  repeat_password: Joi.ref('password')
}).with('password', 'repeat_password');

const requestNewPasswordSchema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
    .required()
})

const setupSchema = Joi.object({
  firstName: Joi.string()
    .pattern(new RegExp('^[a-zA-Z]$'))
    .required(),
  lastName: Joi.string()
    .pattern(new RegExp('^[a-zA-Z]$'))
    .required(),
  role: Joi.string()
  .valid(...['agent','supervisor'])
  .required(),
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
    .required(),
  password: Joi.string()
    .alphanum()
    .min(3)
    .max(30)
    .required()
})

const loginSchema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
    .required().error(errors => {
      errors.forEach(err => {
        switch (err.code) {
          case "any.empty":
            err.message = "The email field cannot be empty.";
            break;
          default:
            break;
        }
      });
    }),
  password: Joi.string()
    .alphanum()
    .min(3)
    .max(30)
    .required()
})

function LoginBox ({ setPopUpMessage }) {
  const { setAuthInfo } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  
  async function logIn() {
    try {
      setLoggingIn(true);
      const inputIsValid = await loginSchema.validateAsync({ email, password });

      if(inputIsValid) {
        const result = await axios.post('https://outplexperformancemanagement.com/api/user/login', {
          email,
          password
        });

        if (result.status === 200) {
          setAuthInfo({ 
            token: result.data.token,
            userInfo: {
              _id: result.data.userInfo._id,
              firstName: result.data.userInfo.firstName,
              lastName: result.data.userInfo.lastName,
              role: result.data.userInfo.role
            },
            expiresAt: result.data.expiresAt
          });
          history.push('/');
        }
        console.log(result);
      } 
    } catch (err) {
      setLoggingIn(false);
      console.dir(err);
    }
  }
  
  return (
    <div className="login-box">
      <Logo />
      <hr className="login-hr" />
      <div className="login-input">
        <label>Email Address</label>
        <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required></input>
      </div>
      <div className="login-input">
        <label>Password</label>
        <input type="password" placeholder="•••••••" value={password} onChange={(e) => setPassword(e.target.value)} required></input>
      </div>
      <button onClick={() => logIn() } className="login-button">
        {loggingIn
          ? <Loading color="#fff"/>
          : <p style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px'
            }}>Sign In</p>
        }
      </button>
      <Link to="/forgot-password" className="login-fgtpw">Forgot your password?</Link>
    </div>
  )
}

function FgtPassword ({ setPopUpMessage }) {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  function onTryAnother() {
    setSent(false);
    setEmail('');
  }

  async function onSend() {
    try {
      setSending(true);
      const result = await axios.post('https://outplexperformancemanagement.com/api/user/request-password-reset', {
        email
      });

      if (result.status === 200) {
        setSending(false);
        setSent(true);
      } else {
        
      }
      console.log(result);
    } catch (error) {
      setSending(false);
      console.log(error)
    }
  }
  
  return (
    <div className="login-box">
      <Logo />
      <hr className="login-hr" />
      { sent && 
        <>
          <p className="fgt-title">We just sent you an email!</p>
          <p className="fgt-headnote">If you don’t see your password reset email, check your spam folder for an email from noreply@outplex.com</p>
          <div className="login-input">
            <input className="sent-email" type="email" value={`Email was sent to ${email}`} placeholder="Enter your email" disabled></input>
          </div>
          <button onClick={() => onSend() } className="login-button">
            {sending
              ? <Loading color="#fff"/>
              : <b style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px'
                }}>Resend me reset instructions</b>
            }
          </button>
          <Link className="fgt-login" onClick={() => onTryAnother() }>Try another email address</Link>
        </> 
      }
      { !sent && 
        <>
          <p className="fgt-title">Forgot your password?</p>
          <p className="fgt-headnote">Enter your email address below, so we can send you password reset instructions.</p>
          <div className="login-input">
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required></input>
          </div>
          <button onClick={() => onSend() } className="login-button">
            {sending
              ? <Loading color="#fff"/>
              : <b style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px'
                }}>Email me reset instructions</b>
            }
          </button>
          <Link to="/login" className="fgt-login">Never mind, let me log in</Link>
        </> 
      }
    </div>
  )
}

function FlashMessage ({ children, color }) {
  const colors = {
    yellow: '#bfa20c',
    red: '#ef7272'
  }

  return (
    <div style={{ color: colors[color] }} className='flash-message'>
      {children}
    </div>
  )
}

function ResetPassword ({ setPopUpMessage }) {
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const searchParams = useSearchParams();
  const [id, ] = useState(searchParams.get('id') || '');
  const [resetToken, ] = useState(searchParams.get('token') || '');
  const [resetting, setResetting] = useState(false);
  const history = useHistory();

  async function onReset() {
    try {
      setResetting(true)
      const result = await axios.post('https://outplexperformancemanagement.com/api/user/reset-password', {
        id,
        newPassword: passwordOne,
        resetToken
      });
      if (result.status === 200) {
        setResetting(false);
        history.push('/login');
      }
      console.log(result);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="login-box">
      <Logo />
      <hr className="login-hr" />
      <p className="reset-title">Reset your password</p>
      <div className="login-input">
        <label>Enter a new password</label>
        <input type="password" placeholder="Type a new password" value={passwordOne} onChange={(e) => setPasswordOne(e.target.value)}></input>
      </div>
      <div className="login-input">
        <label>Confirm your password</label>
        <input type="password" placeholder="Re-enter your password" value={passwordTwo} onChange={(e) => setPasswordTwo(e.target.value)}></input>
      </div>
      <button className="login-button" onClick={() => onReset()}>
        {resetting
          ? <Loading color="#fff"/>
          : <b style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px'
            }}>Save my password</b>
        }
      </button>
    </div>
  )
}

function Setup({ setPopUpMessage }) {
  const history = useHistory();
  const [settingUp, setSettingUp] = useState(false);
  const searchParams = useSearchParams();
  const [email, ] = useState('');
  const [password, setPassword] = useState('');
  const [role, ] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { id } = useParams();
  const [{ data, isLoading, isError }, doFetch] = useAgent({ 
    data: {
      role: '',
      email: ''
    }}, 
    `https://outplexperformancemanagement.com/api/invite/${id}`);

  async function onSetUp() {
    try {
      setSettingUp(true);
      const result = await axios.post('https://outplexperformancemanagement.com/api/user/signup', {
        email: data.data.email,
        password,
        firstName,
        lastName,
        role: data.data.role,
        projectsToAddTo: data.data.projectsToAddTo
      });
      if (result.status === 200) {
        setSettingUp(false);
        history.push('/login');
      } 
      console.log(result);
    } catch (error) {
      setSettingUp(false);
    }
  }

  return (
    <div className="setup-box">
      <Logo />
      <hr className="login-hr" />
      <p className="reset-title">Account Setup</p>
      <div className="setup-form-group">
        <div>
          <div className="login-input">
              <label>Name and Last Name</label>
              <div className="setup-names">
                <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required></input>
                <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required></input>
              </div>
          </div>
          <div className="login-input">
            <label>Role</label>
            <input className="setup-disabled role-input" type="text" value={data.data.role || 'Loading...'} disabled></input>
          </div>
        </div>
        <div>
          <div className="login-input">
            <label>Email Address</label>
            <input className="setup-disabled" type="email" value={data.data.email || 'Loading...'} disabled></input>
          </div>
          <div className="login-input">
            <label>Password</label>
            <input type="password" value={password} placeholder="•••••••" onChange={(e) => setPassword(e.target.value)} required></input>
          </div>
        </div>
      </div>
      <button className="login-button" onClick={() => onSetUp()}>
        {settingUp
          ? <Loading color="#fff"/>
          : <b style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px'
            }}>Set up my account</b>
        }
      </button>
    </div>
  )
}

function Login({ view }) {
  const [popUpMessage, setPopUpMessage] = useState({
    msg: '',
    style: {}
  });
  
  return (
    <div className="login-page">
      <div className="login-section">
        { view === 'login' && <LoginBox setPopUpMessage={setPopUpMessage}/>}
        { view === 'forgot' && <FgtPassword setPopUpMessage={setPopUpMessage}/>}
        { view === 'reset-pw' && <ResetPassword setPopUpMessage={setPopUpMessage}/>}
        { view === 'setup' && <Setup setPopUpMessage={setPopUpMessage}/>}
      </div>
      <p className="login-footnote">Internal tool for OutPLEX employees.</p>
      <p className="login-footnote">You must be invited to log in. Request an invitation from your supervisor.</p>
      {popUpMessage.msg && <Alert msg={popUpMessage.msg} style={popUpMessage.style}/>}
    </div>
  )
}

export default Login; 