import styles from '../styles.module.css';

function MeetingSession({ name, count, selected, onClick }) {
  const withCount = count > 0 ? `( ${count} )` : '';
  
  return (
    <div className={styles['meeting-session']} onClick={onClick}>
      <a className={`${ selected ? styles['selected-session'] : ''}`}>
        {name} {withCount}
      </a>
    </div>
  )
}

export default MeetingSession;