import { SubMenu, SubOption } from './Menu/SubMenu.js';
import { ReactComponent as MeetingIcon } from '../assets/icons/meeting.svg';
import { ReactComponent as EmployeeIcon } from '../assets/icons/employee.svg';
import { ReactComponent as ProjectIcon } from '../assets/icons/project.svg';
import { ReactComponent as TeamIcon } from "../assets/icons/team.svg";
import { ReactComponent as PerformanceIcon } from '../assets/icons/performance.svg';
import { ReactComponent as FeedbackIcon } from "../assets/icons/feedback.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/profile.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings.svg";
import { ReactComponent as HomeIcon } from "../assets/icons/home.svg";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { NavLink, Route, Switch, useParams, Redirect, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../hooks/auth';
import { useProject} from '../hooks/project';
import styles from './Menu/styles.module.css';
import { CreateDropdown, CreateOption } from './Menu/CreateDropdown.js';
import { SettingsDropdown, SettingsHeader, SettingsOption } from './Menu/SettingsDropdown.js';
import axios from 'axios';

const Content = ({ children }) => <div className="content">{children}</div>;

function RightSide ({ children }) {
  return (
    <div className={styles['right-side']}>
      {children}
    </div>
  );
}

function TitleBlock ({title, children}) {
  return (
    <div style={{
      marginLeft: '0.9375rem',
      display: 'flex',
      alignItems: 'center',
    }}>
      {children}
      <p style={{
        fontWeight: 'bold',
        color: '#000000b0',
        marginRight: '1.2rem',
        textTransform: 'capitalize'
      }}>
        {title}
      </p>
    </div>
  )
}

function AuthenticatedApp({ children }) {
  const [settingsDrop, setSettingsDrop] = useState(false);
  const [createDrop, setCreateDrop] = useState(false);
  const { logOut, authState } = useAuth();
  const { id } = useParams();
  const {currentProjectState, setProjectInfo } = useProject();
  const history = useHistory();

  useEffect(() => {
    async function getProject() {
      try {
        if (id) {
          const result = await axios.get(`https://outplexperformancemanagement.com/api/project/${id}`)
          if (result.status === 200) {
            const isUserAllowed = result.data.data.members
              .find(m => m._id === authState.userInfo._id);
            
              if (isUserAllowed) {
              setProjectInfo({
                name: result.data.data.name,
                id: result.data.data._id
              })
            } else {
              history.push("/")
            }
          }
        }
      } catch (err) {
  
      }
    }
    getProject();
   
  }, [])

  return (
    <>
      <Switch>
          <SubMenu>
            <TitleBlock title={`${currentProjectState.name || 'OutPLEX Performance'}`}>
              <LogoIcon style={{
                marginRight: '0.5rem',
                width: '2rem',
                height: '2rem',
                fill: '#f37148',
                fill: '#305c74'
              }}
              />
            </TitleBlock> 
            <Route path={["/project/:id"]}>
              <SubOption title="Meetings" icon={MeetingIcon} to={`/project/${id}/meetings`}  />
              <SubOption title="KPI Reports" icon={PerformanceIcon} to={`/project/${id}/performance-indicators`}/>
              <SubOption title="Reviews" icon={FeedbackIcon} to={`/project/${id}/performance-forms`}/>
            </Route>
            <RightSide>
            <NavLink activeClassName={styles['settings-button-active']} className={styles['settings-button']} exact to='/'>
                <HomeIcon />
              </NavLink>
            <Route path={["/project/:id"]}>
              <CreateDropdown isOpen={createDrop} setIsOpen={setCreateDrop}>
                <CreateOption onSelect={setCreateDrop} title="Employee" to="/employees/create" icon={EmployeeIcon} />
                <CreateOption onSelect={setCreateDrop} title="Meeting" to="/meetings/create" icon={MeetingIcon} />
                <CreateOption onSelect={setCreateDrop} title="PIP" to="/performance/create" icon={PerformanceIcon} />
              </CreateDropdown>       
              {authState.userInfo.role != 'Agent' &&
                <NavLink activeClassName={styles['settings-button-active']} className={styles['settings-button']} to={`/project/${id}/settings/people`}>
                  <SettingsIcon />
                </NavLink>
              }
            </Route>
              <SettingsDropdown profileUrl={null} profileLetter={authState.userInfo.firstName.substring(0, 1)} isOpen={settingsDrop} setIsOpen={setSettingsDrop}>
                <SettingsHeader title={`${authState.userInfo.firstName} ${authState.userInfo.lastName}`} role={authState.userInfo.role} />
                <SettingsOption onSelect={setSettingsDrop} title="Profile" to="/settings/profile" icon={ProfileIcon}/>
                <SettingsOption whenClicked={() => logOut()} onSelect={setSettingsDrop} title="Sign Out" icon={LogoutIcon} />
              </SettingsDropdown>
            </RightSide>
          </SubMenu>
      </Switch>
      <Content>
        {children}
      </Content>
    </>
  );
}

export default AuthenticatedApp;
