import styles from './styles.module.css';
import { useEffect, useState } from 'react';
import { useAgent, useDataApi } from '../../hooks/agent.js';
import { useProject } from '../../hooks/project.js';
import MeetingList from './Content/MeetingList.js';
import MeetingSession from './Content/MeetingSession.js';
import MeetingItem from './Content/MeetingItem.js';
import FilterSection from './Sidebar/FilterSection.js';
import Input from '../Common/Input/';
import Button from '../Common/Button/';
import TypeFilterItem from './Sidebar/TypeFilterItem';
import PersonFilterItem from './Sidebar/PersonFilterItem';
import ProjectFilterItem from './Sidebar/ProjectFilterItem';
import RemovableFilterItem from './Sidebar/RemovableFilterItem';
import { ReactComponent as VideocameraIcon } from '../../assets/icons/filled/videocamera.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/filled/calendar.svg';
import DatePickerItem from './Sidebar/DatePickerItem';
import Modal from '../Common/Modal/';
import MeetingFilter from '../Meetings/Modals/MeetingFilter.js';
import CreateMeeting from '../Meetings/Modals/CreateMeeting.js';
import MeetingDetails from '../Meetings/Modals/MeetingDetails.js';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import Loading from '../Common/Loading'
import Alert from '../Common/Alert';

function Sidebar ({ children }) {
  return (
    <div className={styles['sidebar']}>
      {children}
    </div>
  );
}

function Content ({ children }) {
  return (
    <div className={styles['content']}>
      {children}
    </div>
  );
}

function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

function Meetings ({ children }) {
  const searchParams = useSearchParams();
  const history = useHistory();
  const [view, setView] = useState(searchParams.get('view') || 'coaching');
  const [oneOnOne, setOneOnOne] = useState(searchParams.get('1to1') || false);
  const [triad, setTriad] = useState(searchParams.get('triad') || false);
  const [selectedSupervisors, setSelectedSupervisors] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [createMeetingView, setCreateMeetingView] = useState('create');
  const [popUpMessage, setPopUpMessage] = useState({
    msg: '',
    style: {}
   });

   const [{ data, isLoading, isError }, doFetch] = useAgent(
    { 
      data: [],
      count: {
        shadows: 0,
        coachings: 0
      }
    }, 
    "https://outplexperformancemanagement.com/api/meeting");


  let dateFromParams = searchParams.get('date') && searchParams.get('date').split('-');
  dateFromParams = dateFromParams && new Date(dateFromParams[0], dateFromParams[1] - 1, dateFromParams[2]);

  const [date, setDate] = useState(dateFromParams || null);
  const [meetings, setUrl] = useState([]);
  const [createMeeting, setCreateMeeting] = useState(false);
  const [meetingFilter, setMeetingFilter] = useState(false);
  const [filter, setFilter] = useState('manager');

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [manageMeeting, setManageMeeting] = useState(false);

  useEffect(() => {
    const qs = new URLSearchParams();
    
    view && qs.append('view', view);
    date && qs.append('date', date.toISOString().split('T')[0])
    oneOnOne && qs.append('1to1', oneOnOne);
    triad && qs.append('triad', triad);
    
    history.replace(`?${qs.toString()}`, {})

    const arr = [...selectedAgents, ...selectedSupervisors, ...selectedManagers]
      
    arr.forEach(a => {
      qs.append('participants', a._id)
    });

    doFetch(`https://outplexperformancemanagement.com/api/meeting?${qs.toString()}`)
  }, [history, 
    view, 
    date,
    oneOnOne, 
    triad,
    selectedAgents.length,
    selectedSupervisors.length,
    selectedManagers.length
  ]);


  const removeManager = manager => setSelectedManagers(selectedManagers.filter(m => m._id !== manager._id));
  const removeSupervisor = supervisor => setSelectedSupervisors(selectedSupervisors.filter(s => s._id !== supervisor._id));
  const removeAgent = agent => setSelectedAgents(selectedAgents.filter(a => a._id !== agent._id));


  const toggleItem = (itemName) => {
    let selectedItems = [];
    let setSelectedItems;
  
    switch(itemName) {
      case "agent":
        selectedItems = selectedAgents;
        setSelectedItems = setSelectedAgents;
        break;
      case "manager":
        selectedItems = selectedManagers;
        setSelectedItems = setSelectedManagers;
        break;
      case "supervisor":
        selectedItems = selectedSupervisors;
        setSelectedItems = setSelectedSupervisors;
        break;
      default:
        break;
    }

    return (item) => {
      item.checked = !item.checked;

      if (item.checked) {
        setSelectedItems([...selectedItems, item]);
      } else {
        setSelectedItems(selectedItems.filter(i => i._id !== item._id));
      }
    } 
  }

  function selectMeeting(e, id) {
    e.preventDefault();
    setSelectedMeeting(id);
    setManageMeeting(true)
  }

  const openMeetingFilter = (filter) => {
    setFilter(filter);
    setMeetingFilter(true);
  }

  return (
    <div className={styles['meetings-container']}>
      {/*meetings.isError && <p>Something went wrong...</p> */}
      {meetings.isLoading ? (
        <Loading color="#0f6695"/>
      ) : (
        <div className={styles['meetings-content']}>
          <Sidebar>
            <FilterSection key={1} title="Scheduled Date">
              <DatePickerItem
                resetDateFn={() => setDate(null)}
                selectedDate={date}
                onClick={setDate} 
              />               
            </FilterSection>
            <div className="divider"></div>
            <FilterSection key={2} title="Meeting Type">
              <TypeFilterItem title="One on one" onCheck={() => setOneOnOne(!oneOnOne)} checked={oneOnOne} />
              <TypeFilterItem title="Triad" onCheck={() => setTriad(!triad)} checked={triad} />
            </FilterSection>
            <div className="divider"></div>
            <FilterSection key={3} title="Operations Managers" onClick={() => openMeetingFilter("manager")}>
              {
                selectedManagers.map((manager, index) => (
                  <RemovableFilterItem key={index} onRemove={() => removeManager(manager)}>
                    <PersonFilterItem name={`${manager.firstName} ${manager.lastName}`} url={manager.profilePicUrl} />
                  </RemovableFilterItem>
                ))
              }
            </FilterSection>
            <div className="divider"></div>
            <FilterSection key={4} title="Supervisors" onClick={() => openMeetingFilter("supervisor")}>
              {
                selectedSupervisors.map((supervisor, index) => (
                  <RemovableFilterItem key={index} onRemove={() => removeSupervisor(supervisor)}>
                    <PersonFilterItem name={`${supervisor.firstName} ${supervisor.lastName}`} url={supervisor.profilePicUrl} />
                  </RemovableFilterItem>
                ))
              }
            </FilterSection>
            <div className="divider"></div>
            <FilterSection key={5} title="Agents" onClick={() => openMeetingFilter("agent")}>
              {
                selectedAgents.map((agent, index) => (
                  <RemovableFilterItem key={index} onRemove={() => removeAgent(agent)}>
                    <PersonFilterItem name={`${agent.firstName} ${agent.lastName}`} url={agent.profilePicUrl} />
                  </RemovableFilterItem>
                ))
              }
            </FilterSection>
          </Sidebar>
          <Content>
            <p className={styles['content-title']}>Meetings</p>
            <div className={styles['content-header']}>
              <div className={styles['session-selector']}>
                <MeetingSession name="Coachings" count={data.count.coachings} selected={view === 'coaching'} onClick={() => setView('coaching')} />
                <MeetingSession name="Shadows" count={data.count.shadows} selected={view === 'shadowing'} onClick={() => setView('shadowing')} />
                <div className={styles['buttons']}>
                  {/*<Button
                    color="gradient" 
                    onClick={() => {
                      setCreateMeetingView('myschedule');
                      setCreateMeeting(true)
                    }}>
                    <CalendarIcon /> My Schedule
                  </Button>*/}
                  <Button
                    color="primary" 
                    onClick={() => {
                      setCreateMeetingView('create');
                      setCreateMeeting(true)
                    }}>
                    <VideocameraIcon /> Schedule a meeting
                  </Button>
                </div>
              </div>
            </div>
            <MeetingList>
              {isError && <div>Something went wrong ...</div>}
              <span className={styles['meeting-date']}>
              {date ? 
                  date.toLocaleString(undefined, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })
                : new Date().toLocaleString(undefined, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })
              }
              </span>
              { 
                isLoading 
                ? ( <Loading color="#0f6695"/> )
                : (data.data.map((meeting, index) => {
                  return (
                    <>
                      <MeetingItem key={index} 
                        type={meeting.category === 'one-on-one' ? 'One on one' : 'Triad'}
                        time={`${meeting.happeningAt.timeFrom} - ${meeting.happeningAt.timeTo}`}
                        activity={meeting.purpose}
                        participants={meeting.participants}
                        onClick={(e) => selectMeeting(e, meeting._id)}
                      />
                      {index + 1 < data.data.length ? <div className="divider"></div> : ""}
                    </>
                  )
                }))
              }
            </MeetingList>
            <Modal show={createMeeting} onHide={() => setCreateMeeting(false)}>
              {createMeeting && <CreateMeeting 
                view={createMeetingView} 
                setView={setCreateMeetingView} 
                onCancel={() => setCreateMeeting(false)} 
                setPopUpMessage={setPopUpMessage}
              />}
            </Modal>
            <Modal show={meetingFilter} onHide={() => setMeetingFilter(false)}>
              {meetingFilter && 
                <MeetingFilter filter={filter} 
                  onSelectFilter={setFilter}
                  selectedManagers={selectedManagers}
                  selectedSupervisors={selectedSupervisors}
                  selectedAgents={selectedAgents}
                  onSelectAgent={toggleItem("agent")}
                  onSelectManager={toggleItem("manager")}
                  onSelectSupervisor={toggleItem("supervisor")}
                />
              }
            </Modal>
            <Modal show={manageMeeting} onHide={() => setManageMeeting(false)}>
              {manageMeeting && <MeetingDetails 
                meetingId={selectedMeeting}
                onCancel={() => setManageMeeting(false)} 
                setPopUpMessage={setPopUpMessage}
              />}
            </Modal>
            {popUpMessage.msg && <Alert msg={popUpMessage.msg} style={popUpMessage.style}/>}
          </Content>
        </div>
      )}
    </div>
  );
}

export {
  Meetings
}