import styles from './styles.module.css';
import { useEffect, useRef, useState } from 'react';
import Input from '../../Common/Input/';
import Checkbox from '../../Common/Checkbox/';
import Button from '../../Common/Button/';
import ProfilePic from '../../Common/ProfilePic/';
import SelectButton from '../../Common/SelectButton/';
import DefaultDropdown from '../../Common/Dropdowns/DefaultDropdown/';
import CalendarDropdown from '../../Common/Dropdowns/CalendarDropdown/';
import MeetingSchedule from '../Modals/MeetingSchedule/';
import { useOutsideClick } from '../../../hooks/dom';
import { ReactComponent as ScheduleIcon } from '../../../assets/icons/filled/schedule.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/filled/check.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icons/filled/external-link.svg';
import { ReactComponent as UserGroupIcon } from '../../../assets/icons/filled/user-group.svg';
import { useAgent } from '../../../hooks/agent';
import Loading from '../../Common/Loading';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useProject } from '../../../hooks/project';
import { useAuth } from '../../../hooks/auth';

import { getTimeRanges } from '../../../utils.js';

const PeopleItem = ({ name, url, ...rest}) => (
  <div className={styles['people-item']} {...rest}>
    <ProfilePic width="1.5625rem" height="1.5625rem" url={url} letter={name.substring(0,1)}/>
    <p>{name}</p>
  </div>
);

function FilterItem({ role, children, ...rest }) {
  return (
    <div className={styles['filter-item']}>
      <Checkbox {...rest}/>
      {children}
      <p className={styles['filter-item-role']}>{role}</p>
    </div>
  );
}

const currentDate = new Date();
const formatDate = (date) => {
  return date.toLocaleString('en-GB', { 
    day: 'numeric', 
    month: 'short',
    year: 'numeric',
    weekday: 'short'
  })
};

const steps = 2;
const times = getTimeRanges(15, 'en');

function CreateMeeting({ view = 'create', setView, onCancel, setPopUpMessage }) {
  const { authState } = useAuth();
  const history = useHistory();
  const meetingContainerRef = useRef();
  const [currentScroll, setCurrentScroll] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [participantsFilter, setParticipantsFilter] = useState('search');
  const [creating, setCreating] = useState(false);
  const { currentProjectState } = useProject();

  const [date, setDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
  );
  const [timeFrom, setTimeFrom] = useState('12:00 p.m.');
  const [timeTo, setTimeTo] = useState('12:00 p.m.');
  const [type, setType] = useState('(1:1) One on one');
  const [purpose, setPurpose] = useState('Coaching');
  const [meetingLink, setMeetingLink] = useState('');
  const [query, setQuery] = useState('');
  
  const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
  const [isTimeFromSelectOpen, setIsTimeFromSelectOpen] = useState(false);
  const [isTimeToSelectOpen, setIsTimeToSelectOpen] = useState(false);
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false);
  const [isPurposeSelectOpen, setIsPurposeSelectOpen] = useState(false);
  const [isProjectSelectOpen, setIsProjectSelectOpen] = useState(false);

  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const [{ data, isLoading, isError }, doFetch] = useAgent({ 
    data: {
      members: []
    }
  }, 
    `https://outplexperformancemanagement.com/api/project/${currentProjectState.id}`);

  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef  = useRef();

  const noOverflow = isDateSelectOpen 
    || isTimeFromSelectOpen 
    || isTimeToSelectOpen
    || isTypeSelectOpen
    || isPurposeSelectOpen 
    || isProjectSelectOpen ? styles['select-open'] : '';
  
  const isSearchEnabled = participantsFilter === 'search' ? true : false;
  const cancelText = currentStep === 1 ? 'Cancel' : 'Edit';
  const ctaText = currentStep === 1 ? 'Continue' : 'Schedule';

  function ctaFn (step) {
    switch(step) {
      case 1:
        setCurrentStep(2);
        break;
      case 2:
        createMeeting();
        break;
    }
  }

  function cancelFn (step) {
    switch(step) {
      case 1:
        onCancel();
        break;
      case 2:
        setCurrentStep(1);
        break;
    }
  }

  function updateSelect(action, value) {
    switch(action) {
      case "date": 
        setDate(value);
        setIsDateSelectOpen(false);
        if (showCalendar) setShowCalendar(false);
        break;
      case "timeFrom":
        setTimeFrom(value);
        setIsTimeFromSelectOpen(false);
        break;
      case "timeTo":
        setTimeTo(value);
        setIsTimeToSelectOpen(false);
        break;
      case "type":
        setType(value);
        setIsTypeSelectOpen(false);
        break;
      case "purpose":
        setPurpose(value);
        setIsPurposeSelectOpen(false);
        break;
    }
  }

  useOutsideClick(calendarRef, () => setShowCalendar(false));

  useEffect(() => {
    meetingContainerRef.current.addEventListener('scroll', function(e) {
      const element = e.srcElement;
      setCurrentScroll(element.scrollTop / (element.scrollHeight - element.clientHeight));
    });
  }, [])

  useEffect(() => {
    setSelectedParticipants([authState.userInfo._id]);
  }, [])

  function toggleUser(id) {
    if (isUserSelected(id)) {
      const listWithoutCurrentUser = selectedParticipants.filter(userId => userId != id);
      setSelectedParticipants(listWithoutCurrentUser);
    } else {
      setSelectedParticipants([...selectedParticipants, id]);
    }
  }

  function isUserSelected(id) {
    return (selectedParticipants.includes(id))
  }

  function isUserDisabled(id) {
    return (authState.userInfo._id === id);
  }

  async function createMeeting() {
    try {
      setCreating(true);
      const result = await axios.post('https://outplexperformancemanagement.com/api/meeting', {
        happeningAt: {
          day: date,
          timeFrom: timeFrom,
          timeTo: timeTo
        },
        category: type.toLowerCase() === '(1:1) one on one' ? 'one-on-one' : type.toLowerCase(),
        purpose: purpose.toLowerCase(),
        link: meetingLink,
        project: currentProjectState.id,
        participants: selectedParticipants,
        createdBy: authState.userInfo._id
      });

        if (result.status === 201) {
          setCreating(false);
          onCancel();
          setPopUpMessage({
            msg: `Creation successful..`,
            style: {
              borderTop: '5px solid #208bc5',
              backgroundImage: 'linear-gradient(-180deg, #fff, #f5f8f9 99%)',
              color: '#0c6493',
              width: '300px',
              top: '120px'
            }
          })
          setTimeout(function() { 
            setPopUpMessage({
              msg: "",
              style: {}
            })
            history.go(window.location.href);
          }, 3000);
        }
        console.log(result);
    } catch (err) {
      setCreating(false);
      console.dir(err);
    }
  }

  return (
    <div ref={meetingContainerRef} className={`${styles['create-meeting']} ${noOverflow}`}>
      <div className={styles['create-meeting-header']}>
        <p className={styles['header-name']}>Schedule a Meeting</p>
        <p className={styles['step-count']}>{`Step ${currentStep} of ${steps}`}</p>
      </div>
      <div className={styles['create-meeting-content']}>
        {(currentStep === 1 && view === 'create') &&
        <>
          <div className={styles['date-time']}>
            <div className={styles['date']}>
              <p>Date</p>
              <SelectButton value={formatDate(date)} isOpen={isDateSelectOpen} setIsOpen={setIsDateSelectOpen}>
                <CalendarDropdown selectedDate={date} onClick={(value) => updateSelect('date', value)} />
              </SelectButton>
            </div>
            <div className={styles['time']}>
              <div className={styles['time-opts']}>
                <span>Time</span>
                <span onClick={() => setView('myschedule')}>See Schedule</span>*
              </div>
              <div className={styles['from-to']}>
                <div className={styles['from']}>
                <SelectButton value={timeFrom} isOpen={isTimeFromSelectOpen} setIsOpen={setIsTimeFromSelectOpen}>
                  <DefaultDropdown centeredText
                    maxHeight="280px"
                    items={times}
                    selectedItem={timeFrom}
                    onClick={(value) => updateSelect('timeFrom', value)}
                  />
                </SelectButton>
                </div>
                <div className={styles['to']}>
                  <SelectButton value={timeTo} isOpen={isTimeToSelectOpen} setIsOpen={setIsTimeToSelectOpen}>
                    <DefaultDropdown centeredText
                      maxHeight="280px"
                      items={times}
                      selectedItem={timeTo}
                      onClick={(value) => updateSelect('timeTo', value)}
                    />
                  </SelectButton>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['type-purpose']}>
            <div className={styles['type']}>
              <p>Type</p>
              <SelectButton value={type} isOpen={isTypeSelectOpen} setIsOpen={setIsTypeSelectOpen}>
                <DefaultDropdown onClick={(value) => updateSelect('type', value)}
                  items={['(1:1) One on one', 'Triad']}
                  selectedItem={type}
                />
              </SelectButton>
            </div>
            <div className={styles['purpose']}>
              <p>Purpose</p>
              <SelectButton value={purpose} isOpen={isPurposeSelectOpen} setIsOpen={setIsPurposeSelectOpen}>
                <DefaultDropdown onClick={(value) => updateSelect('purpose', value)}
                  items={['Coaching', 'Shadowing']}
                  selectedItem={purpose}
                />
              </SelectButton>
            </div>
          </div>
          <div className={styles['location']}>
            <div className={styles['address-link']}>
              <p>Meeting Link</p>
              <Input placeholder="Type a meeting link"
                defaultValue={meetingLink} 
                onChange={(e) => setMeetingLink(e.target.value)}
              />
            </div>
          </div>
          <div className={styles['participants']}>
            <p>Participants</p>
            <div className={styles['participants-header']}>
              <Input placeholder={`Search for someone within ${currentProjectState.name}`}
                  onChange={(e) => setQuery(e.target.value)}
                  disabled={!isSearchEnabled} 
                />
              <div className={styles['search-or-selected']}>
                <a onClick={() => setParticipantsFilter('search')} 
                  className={participantsFilter === 'search' ? styles['selected-selector'] : ''}>
                  Search
                </a>
                <a onClick={() => setParticipantsFilter('selected')} 
                  className={participantsFilter === 'selected' ? styles['selected-selector'] : ''}>
                  Selected <span>{selectedParticipants.length}</span>
                </a>
              </div>
            </div>
            <div className={styles['search-content']}>
              <div className={styles['filter-content-items']}>
                {isError && <div>Something went wrong ...</div>}
                { participantsFilter === 'search' && 
                  (isLoading 
                    ? ( <Loading color="#0f6695"/> )
                    : (data.data.members
                      .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                      .map((user, index) => {
                        return (
                          <FilterItem key={index} role={`${isUserDisabled(user._id) ? user.role + " (You)" : user.role}`}
                            onCheck={() => toggleUser(user._id)} 
                            checked={isUserSelected(user._id)}
                            disabled={isUserDisabled(user._id)}>
                            <PeopleItem url={user.url}
                              name={`${user.firstName} ${user.lastName}`}
                            />
                          </FilterItem>
                        )
                      }))
                  )
                }
                { participantsFilter === 'selected' &&
                  ( data.data.members
                    .filter(user => isUserSelected(user._id))
                    .map((user, index) => {
                      return (
                        <FilterItem key={index} role={`${isUserDisabled(user._id) ? user.role + " (You)" : user.role}`}
                          onCheck={() => toggleUser(user._id)} 
                          checked
                          disabled={isUserDisabled(user._id)}>
                          <PeopleItem url={user.url} 
                            name={`${user.firstName} ${user.lastName}`}
                          />
                        </FilterItem>
                    )})
                  )
                }
                
              </div>
            </div>
          </div>
        </>
        }
        {(currentStep === 1 && view === 'myschedule') &&
          <>
            <div className={styles['myschedule-header']}>
              <p onClick={() => setView('create')}>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                </svg>
                Create a meeting
              </p>
              <span style={{margin: '0 5px'}}>|&nbsp;</span>
              <p className={styles['myschedule-date']}>{`${authState.userInfo.firstName} ${authState.userInfo.lastName}'s`} Schedule for {formatDate(date)} 
                <span onClick={() => setShowCalendar(true)}>Change</span>
                {showCalendar && 
                  <CalendarDropdown ref={calendarRef} selectedDate={date} onClick={(value) => updateSelect('date', value)} />
                }
              </p>
            </div>
            <MeetingSchedule />
            <p className={styles['myschedule-note']}>
              Dots of the same color indicate the that it is the same meeting.
            </p>
          </>
        }
        {currentStep === 2 &&
          <>
            <div className={styles['summary']}>
              <div>
                <ScheduleIcon />
                <p>Summary</p>
              </div>
              <div className={styles['summary-details']}>
                <span className={styles['summary-part']}>
                  {type.toLowerCase() === '(1:1) one on one' ? 'One on one' : "Triad"}
                </span>
                <span className={styles['summary-part']}>
                  {purpose.toLowerCase() === 'shadowing' ? 'shadow' : 'coach'}
                </span>
                <span>on</span>
                <span className={styles['summary-part']}>
                  {
                    date.toLocaleString(undefined, {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    })
                  }
                </span>
                <span>from</span>
                <span className={styles['summary-part']}>{timeFrom} - {timeTo}</span>
              </div>
            </div>
            <div className={styles['summary']}>
              <div>
                <ExternalLinkIcon />
                <p>Meeting Link</p>
              </div>
              <div>
                {meetingLink ? meetingLink : "You have not provided a link"}
              </div>
            </div>
            <div className={styles['summary']}>
              <div>
                <CheckIcon />
                <p>Project</p>
              </div>
              <div>{currentProjectState.name}</div>
            </div>
            <div className={styles['summary']}>
              <div>
                <UserGroupIcon />
                <p>Participants</p>
              </div>
              <div>
                {
                  data.data.members
                    .filter(user => isUserSelected(user._id))
                    .map((user, index) => {
                      return (
                        <PeopleItem style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        key={index} 
                        url={user.url} 
                        name={`${user.firstName} ${user.lastName}`}
                        />
                    )})
                }
              </div>
            </div>
          </>
        }
      </div>
      {((currentStep === 1 && view === 'create') || (currentStep === 2)) &&
        <div className={styles['create-meeting-footer']}>
          <div className={styles['create-meeting-buttons']}>
            <Button color="transparent" onClick={() => cancelFn(currentStep)}>{cancelText}</Button>
            <Button style={{ 
              minWidth: '70px',
              maxHeight: '40px',
              height: '40px'
            }} disabled={selectedParticipants.length < 2} color="primary" onClick={selectedParticipants.length < 2 ? null : () => ctaFn(currentStep)}>
              {creating
                ? <Loading color="#fff"/>
                : (ctaText)
              } 
            </Button>
          </div>
        </div> 
      }
    </div>
  );
}

export default CreateMeeting;