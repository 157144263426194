import { useState } from "react";
import './CalendarDatePicker.scss';
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/right-arrow.svg';

const currentDate = new Date();
const weeksCount = 6;
const daysPerWeek = 7;
const calendarGridSize = weeksCount * daysPerWeek;

const isToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() 
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear();
}

const isValidMonth = month => month >= 0 || month <= 11;
const getPreviousMonth = month => month === 0 ? 11 : month - 1;
const getNextMonth = month => month === 11 ? 0 : month + 1;
const getNumberOfDays = (year, month) => new Date(year, month + 1, 0).getDate();

function getMonthDaysGrid(year, month) {
  const monthDaysArray = []; // Always has 42 days (6x7) (previous, current and next month's)
  const monthDaysGrid = [];
  const monthFirstDay = new Date(year, month).getDay(); // Day as in Sunday or Monday. From 0 to 6.
  const currentMonthDays = getNumberOfDays(year, month);
  const previousMonth = getPreviousMonth(month);
  const nextMonth = getNextMonth(month); 
  const nextOrCurrentYear = nextMonth === 0 ? year + 1 : year;
  
  let previousOrCurrentYear;
  let previousMonthDetails;
  
  if (monthFirstDay > 0) {
    const previousMonthDays = getNumberOfDays(year, previousMonth);
    previousMonthDetails = {
      from: (previousMonthDays - monthFirstDay) + 1,
      to: previousMonthDays
    }
  }

  const nextMonthDetails = {
    from: 1,
    to: calendarGridSize - (monthFirstDay + currentMonthDays)
  }

  if (previousMonthDetails != undefined) {
    previousOrCurrentYear = previousMonth === 11 ? year - 1 : year;
    for(let i = previousMonthDetails.from; i <= previousMonthDetails.to; i++) {
      monthDaysArray.push(new Date(previousOrCurrentYear, previousMonth, i));
    }
  }

  for(let i = 1; i <= currentMonthDays; i++) {
    monthDaysArray.push(new Date(year, month, i));
  }

  for(let i = nextMonthDetails.from; i <= nextMonthDetails.to; i++) {
    monthDaysArray.push(new Date(nextOrCurrentYear, nextMonth, i));
  }

  for(let i = 0; i < weeksCount; i++) {
    let start = daysPerWeek * (i);
    let end = daysPerWeek * (i + 1);
    monthDaysGrid.push(monthDaysArray.slice(start, end));
  }

  return monthDaysGrid;
}

function CalendarDatePicker ({ selectedDate, onClick }) {
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear())
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [monthDaysGrid, setMonthDaysGrid] = useState(getMonthDaysGrid(selectedYear, selectedMonth));
  
  function updateYearMonth(action) {
    let month, year;
    
    switch(action) {
      case "increment":
        month = getNextMonth(selectedMonth);
        year = month === 0 ? selectedYear + 1 : selectedYear;
        break;
      case "decrement":
        month = getPreviousMonth(selectedMonth);
        year = month === 11 ? selectedYear - 1 : selectedYear;
        break;
    }

    setSelectedYear(year);
    setSelectedMonth(month);
    setMonthDaysGrid(getMonthDaysGrid(year, month));
  }

  function fn(date, index) {
    const day = date.getDate();
    const isSelected = selectedDate && selectedDate.getTime() === date.getTime();
    
    const modifiers = `
      ${isToday(date) ? 'calendar-datepicker__date--today' : ''}
      ${isSelected ? 'calendar-datepicker__date--selected' : ''}
    `;
    
    const setDate = onClick ? () => onClick(date) : null;

    return (
      <span className={`calendar-datepicker__date ${modifiers}`} 
        key={index} 
        onClick={setDate}
      >{day}
      </span>
    );
  }

  return (
    <div className='calendar-datepicker'>
      <div className='calendar-datepicker__header'>
        <div className='calendar-datepicker__prev-month' 
          onClick={() => updateYearMonth('decrement')}
        >
          <RightArrowIcon />
        </div>
        <span className='calendar-datepicker__caption'>
          {new Date(selectedYear, selectedMonth).toLocaleString(undefined, 
            { month: 'long', 
              year: 'numeric' 
            })
          }
        </span>
        <div className='calendar-datepicker__next-month'
          onClick={() => updateYearMonth('increment')}
        >
          <RightArrowIcon />
        </div>
      </div>
      <div className='calendar-datepicker__grid'>
        <div className='calendar-datepicker__weekdays-row'>
          <span>Su</span>
          <span>Mo</span>
          <span>Tu</span>
          <span>We</span>
          <span>Th</span>
          <span>Fr</span>
          <span>Sa</span>
        </div>
        {
          monthDaysGrid.map((week, index) => (
            <div key={index} className='calendar-datepicker__dates-row'>
              {week.map(fn)}
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default CalendarDatePicker;