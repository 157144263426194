import styles from './styles.module.css';
import CalendarDatePicker from '../../Common/CalendarDatePicker/';
import Button from '../../Common/Button/';

function DatePickerItem({ resetDateFn, ...rest } ) {
  const clearDateClass = `
    ${styles['clear-selected-date']} 
    ${rest.selectedDate ? styles['enabled'] : ''}
  `;

  return (
    <div className={styles['date-picker-item']}>
      <CalendarDatePicker {...rest} /> 
      <Button style={{ padding: '0.5rem 0.9375rem', fontSize: '0.9375rem', marginTop: '0.625rem' }}
        color="gradient" 
        onClick={() => resetDateFn()} 
        disabled={!rest.selectedDate}
      >Clear selected date
      </Button>
    </div>
  );
}

export default DatePickerItem;