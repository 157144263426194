import styles from './CreateProject.module.css';
import { useEffect, useRef, useState } from 'react';
import Input from '../../Common/Input/';
import Checkbox from '../../Common/Checkbox/';
import Button from '../../Common/Button/';
import ProfilePic from '../../Common/ProfilePic/';
import { useAgent } from '../../../hooks/agent';

const PeopleItem = ({ name, url }) => (
  <div className={styles['people-item']}>
    <ProfilePic width="1.5625rem" height="1.5625rem" url={url} />
    <p>{name}</p>
  </div>
);

function FilterItem({ role, note, children, ...rest }) {
  const color = note.color === 'green' ? "#07780c" : "#cd1c0f";
  
  return (
    <div className={styles['filter-item']}>
      <Checkbox {...rest}/>
      {children}
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        {note && 
          <p style={{ color: color, marginRight: '20px' }} className={styles['filter-item-role']}>
            {note.msg}
          </p>
        }
        <p className={styles['filter-item-role']}>{role}</p>
      </div>
      
    </div>
  );
}

function ManageProject({ id, onCancel }) {
  const meetingContainerRef = useRef();
  const [usersFilter, setUsersFilter] = useState('team');

  const [teamData, ] = useAgent({ 
    data: {
      id: '',
      name: 'Loading...',
      participants: []
    }
  }, `https://outplexperformancemanagement.com/api/team/${id}`);

  const [usersData, doFetch] = useAgent({ 
    data: []
  }, `https://outplexperformancemanagement.com/api/user`);

  const [name, setName] = useState('');
  const [query, setQuery] = useState('');
  const [inTeamList, setInTeamList] = useState([]);
  const [notInTeamList, setNotInTeamList] = useState([]);
  
  function toggleUser(id, where) {
    let list = where === 'within' ? [...inTeamList] : [...notInTeamList];
    const notes = {
      within: {
        checkedNote: '',
        uncheckedNote: 'will be removed'
      },
      outside: {
        checkedNote: 'will be added',
        uncheckedNote: ''
      }
    }
    
    list = list.map(user => {
      if (user._id === id) {
        user.checked = !user.checked;
        user.note = user.checked ? notes[where].checkedNote : notes[where].uncheckedNote;
      }
      return user;
    })

    where === 'within' ? setInTeamList([...list]) : setNotInTeamList([...list]);
  }

  function isUserInTeam(id) {
    return (teamData.data.data.participants.find(user => id === user._id) != undefined)
  }

  useEffect(() => {
    setName(teamData.data.data.name);
  }, [teamData.data.data.name])

  useEffect(() => {
    const within = teamData.data.data.participants.map(user => {
      return { ...user, 
        checked: true, 
        note: ''
      }
    })
    const notIn = usersData.data.data
      .filter(user => !isUserInTeam(user._id))
      .map(user => {
        return { ...user, 
          checked: false, 
          note: ''}
      })
    setInTeamList([...within]);
    setNotInTeamList([...notIn]);
  }, [teamData.data.data.participants, usersData.data.data])

  return (
    <div ref={meetingContainerRef} className={`${styles['create-meeting']}`}>
      <div className={styles['create-meeting-header']}>
        <p className={styles['header-name']}>Manage {teamData.data.data.name}</p>
      </div>
      <div className={styles['create-meeting-content']}>
        <div className={styles['location']}>
          <div className={styles['address-link']}>
            <p>Edit your team's current name</p>
            <Input placeholder={`Type in to edit your team's current name (${teamData.data.data.name})`} value={name} onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className={styles['participants']}>
          <p>Toggle checkbox to add or remove</p>
          <div className={styles['participants-header']}>
            <div className={styles['participants-search-container']}>
              <Input placeholder={`Search for someone ${ usersFilter === 'team' ? 'within your team' : 'outside your team'}`}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className={styles['search-or-selected']}>
              <a onClick={() => setUsersFilter('team')} 
                className={usersFilter === 'team' ? styles['selected-selector'] : ''}>
                In team <span>{inTeamList.length}</span>
              </a>
              <a onClick={() => setUsersFilter('noteam')} 
                className={usersFilter === 'noteam' ? styles['selected-selector'] : ''}>
                Not in team <span>{notInTeamList.length}</span>
              </a>
            </div>
          </div>
          <div className={styles['search-content']}>
            <div className={styles['filter-content-items']}>
            {teamData.isError && <div>Something went wrong ...</div>}
            { usersFilter === 'team' && 
              (teamData.isLoading 
                ? ( <div>Loading....</div> )
                : (inTeamList
                  .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                  .map((user, index) => {
                    return (
                      <FilterItem key={index} role={user.role} note={{ color: 'red', msg: user.note}}
                        onCheck={() => toggleUser(user._id, 'within')} 
                        checked={user.checked}>
                        <PeopleItem url="https://i.ibb.co/RjP9Rd7/profile-pic.jpg" 
                          name={`${user.firstName} ${user.lastName}`}
                        />
                      </FilterItem>
                    )
                  }))
              )
            }
            { usersFilter === 'noteam' &&
              ( notInTeamList
                .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                .map((user, index) => {
                  return (
                    <FilterItem key={index} role={user.role} note={{ color: 'green', msg: user.note}}
                      onCheck={() => toggleUser(user._id, 'outside')} 
                      checked={user.checked}>
                      <PeopleItem url="https://i.ibb.co/RjP9Rd7/profile-pic.jpg" 
                        name={`${user.firstName} ${user.lastName}`}
                      />
                    </FilterItem>
                )})
              )
            }
            </div>
          </div>
        </div>
      </div>
      <div className={styles['create-meeting-footer']}>
        <div className={styles['create-meeting-buttons']}>
          <Button color="transparent" text="Cancel" onClick={() => onCancel()} />
          <Button color="primary" text="Save Changes" />
        </div>
      </div> 
    </div>
  );
}

export default ManageProject;