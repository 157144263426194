import { NavLink } from "react-router-dom"
import styles from './styles.module.css';

function SubOption ({ title, to, icon: Icon, count }) {
  return (
    <NavLink activeClassName={styles['suboption-active']} to={to}>
      <div>
        <Icon />
        <p>{title}</p>
        { count && <div className={styles['filter-option-count']}>{count}</div> }
      </div>
    </NavLink>
  )
}

function SubMenu ({ children }) {
  return (
    <div className={styles['submenu-container']}>
      {children}
    </div>
  )
}

export {
  SubMenu,
  SubOption
}