import styles from './styles.module.css';
import ProfilePic from '../../Common/ProfilePic/';

function PersonFilterItem({ name, url }) {
  return (
    <div className={styles['person-filter-item']}>
      <div className={styles['profile-pic']}>
        <ProfilePic width="1.5625rem" height="1.5625rem" url={url} letter={name.substring(0,1)}/>
      </div>
      <span>{name}</span>
    </div>
  )
}

export default PersonFilterItem;