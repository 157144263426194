import styles from './styles.module.css';
import { ReactComponent as NewIcon } from '../../../assets/icons/new.svg';

function FilterSection({ title, children, onClick }) {
  return (
    <div className={styles['filter-section']}>
      <div className={styles['filter-section-header']}>
        <p>{title}</p>
        { onClick && 
          <div onClick={onClick}>
            <NewIcon/>
          </div> 
        }
      </div>
      {children}
    </div>
  )
}

export default FilterSection;